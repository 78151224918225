import React from 'react'
import { node } from 'prop-types'

const GenericComponent = ({ children }) => (
  <div className="di-f fldi-c">{children}</div>
)

GenericComponent.propTypes = {
  children: node
}

GenericComponent.defaultProps = {
  children: null
}

export default GenericComponent
