import React, { Component } from 'react'
import { any, bool, func } from 'prop-types'

// modules
import Formatter from '@modules/formatter'
import {
  handleValidSubmit,
  handleApiResponse
} from '@compositions/Communities/modules/helpers'

// components
import Form from '@components/Form'
import Description from './components/Description'
import Photos from './components/Photos'

import {
  STATE_FORM_IS_VALID,
  STATE_FORM_REQUEST_STATUS,
  STATE_FORM_REQUEST_ERROR,
  STATE_FORM_REQUEST_SUCCEED,
  STATE_VALID_TEXT,
  STATE_COMMUNITY
} from '@compositions/Communities/modules/constants'

class Overview extends Component {
  state = {
    [STATE_FORM_IS_VALID]: false,

    [STATE_FORM_REQUEST_STATUS]: false,
    [STATE_FORM_REQUEST_ERROR]: null,
    [STATE_FORM_REQUEST_SUCCEED]: false,
    [STATE_VALID_TEXT]: true
  }

  constructor(props) {
    super(props)
    this.overviewSubmit = React.createRef()
  }

  handleSubmit(params) {
    const { setPendingChanges, setUpdatedCommunity } = this.props
    const { description } = params.model
    params.model = {
      description: Formatter.stripBreakLines(description)
    }
    this.setState(
      { [STATE_FORM_REQUEST_STATUS]: true },
      handleValidSubmit(
        params,
        handleApiResponse(state => {
          const communityData = state[STATE_COMMUNITY]
          if (communityData) {
            setUpdatedCommunity(state[STATE_COMMUNITY])
            setPendingChanges(false)
          }
          this.setState(state)
        })
      )
    )
  }
  render() {
    const {
      community,
      newLogo,
      onLogoAdded,
      isFetching,
      setPendingChanges,
      hasEditAccess
    } = this.props
    const {
      [STATE_FORM_IS_VALID]: isValidForm,
      [STATE_FORM_REQUEST_STATUS]: isUpdating,
      [STATE_FORM_REQUEST_ERROR]: errorMessage,

      [STATE_VALID_TEXT]: isValidText
    } = this.state
    const { description } = community || {}

    let descriptionText = ''
    let hasPending = false

    if (description) {
      if (description.pending) {
        hasPending = true
        descriptionText = description.pending
      } else if (description.approved) {
        descriptionText = description.approved
      }
    }
    errorMessage && console.warn(errorMessage)
    return (
      <>
        <Form
          ref={this.overviewSubmit}
          className="di-f fldi-c"
          onValidSubmit={model =>
            hasEditAccess &&
            this.handleSubmit({ communityId: community.id, model })
          }
          onValid={() => this.setState({ [STATE_FORM_IS_VALID]: true })}
          onInvalid={() => this.setState({ [STATE_FORM_IS_VALID]: false })}
          onChange={model => {
            this.setState({ [STATE_VALID_TEXT]: !!model.description })
            setPendingChanges(
              true,
              () => this.handleSubmit({ communityId: community.id, model }),
              () =>
                this.overviewSubmit.current.reset({
                  description: descriptionText
                })
            )
          }}
        >
          <Description
            hasPending={hasPending}
            descriptionText={descriptionText}
            isValidForm={isValidForm}
            isUpdating={isUpdating}
            isValidText={isValidText}
            readOnly={isFetching}
            disabled={!hasEditAccess}
          />
        </Form>
        <Form className="di-f fldi-c">
          <Photos
            communityId={community.id}
            newLogo={newLogo}
            onLogoAddedSuccess={onLogoAdded}
            readOnly={!hasEditAccess}
          />
        </Form>
      </>
    )
  }
}

Overview.propTypes = {
  community: any,
  newLogo: any,
  isFetching: bool,
  hasEditAccess: bool,
  setPendingChanges: func,
  setUpdatedCommunity: func,
  onLogoAdded: func
}

Overview.defaultProps = {
  community: null,
  newLogo: null,
  isFetching: false,
  hasEditAccess: false,
  setPendingChanges: () => null,
  setUpdatedCommunity: () => null,
  onLogoAdded: () => null
}

export default Overview
