import Router from '@modules/router'
import { REST_API_ENDPOINTS } from '@modules/constants'
import { Resource } from '@modules/rest-api'

import { ToastNotify } from '@compositions/Toast'

import Formatter from '@modules/formatter'

import {
  STATE_COMMUNITY,
  STATE_COMMUNITY_REQUEST_STATUS,
  STATE_COMMUNITY_REQUEST_ERROR,
  STATE_FORM_REQUEST_STATUS,
  STATE_FORM_REQUEST_ERROR,
  STATE_FORM_REQUEST_SUCCEED,
  STATE_COMMUNITY_HAS_PENDING_CHANGES,
  STATE_SHOW_CONFIRM_PENDING_CHANGES
} from '../constants'

export const handleFetchResource = (setSyntheticBreadcrumbItems, ctx = {}) => (
  err,
  res
) => {
  if (err) {
    const { response } = err || {}
    if (response) {
      const { errors } = response.data || []
      const resourceNotFound =
        errors && errors.find(error => error.field === 'communityId')
      if (resourceNotFound) {
        Router.go('/404')
      }
    }
    return ctx.setState({
      [STATE_COMMUNITY_REQUEST_STATUS]: false,
      [STATE_COMMUNITY_REQUEST_ERROR]: err
    })
  }
  const { data } = res || {}
  if (data && data.results) {
    setSyntheticBreadcrumbItems([
      {
        key: data.results.id,
        text: data.results.name
      }
    ])
    ctx.resultsCopy = data.results
  }
  return ctx.setState({
    [STATE_COMMUNITY]: data,
    [STATE_COMMUNITY_REQUEST_STATUS]: false
  })
}

const cleanFeesModel = feesModel => {
  const fees = []
  const feesEntries = Object.entries(feesModel)
  for (let fee of feesEntries) {
    const FeeTypeId =
      (fee[0] && parseInt(fee[0].replace('feeTypeId-', ''))) || ''
    const careTypesObj = fee[1]
    if (careTypesObj && typeof careTypesObj === 'object') {
      const careTypes = Object.entries(careTypesObj)
      if (careTypes && careTypes.length > 0) {
        for (let careType of careTypes) {
          const CareTypeCode =
            (careType[0] && careType[0].replace('careTypeCode-', '')) || ''
          const amount = careType[1]
          fees.push({
            FeeTypeId,
            CareTypeCode,
            amount: Formatter.number(amount) || 0
          })
        }
      }
    }
  }
  return fees
}

const cleanRoomChargesModel = roomChargesModel => {
  const roomCharges = []
  const roomChargesEntries = Object.entries(roomChargesModel)
  for (let roomCharge of roomChargesEntries) {
    const roomTypeId =
      (roomCharge[0] && parseInt(roomCharge[0].replace('roomTypeId-', ''))) || 0
    const careTypesObj = roomCharge[1]
    if (careTypesObj && typeof careTypesObj === 'object') {
      const careTypes = Object.entries(careTypesObj)
      if (careTypes && careTypes.length > 0) {
        for (let careType of careTypes) {
          const careTypeCode =
            (careType[0] && careType[0].replace('careTypeCode-', '')) || ''
          const amount = careType[1]
          roomCharges.push({
            roomTypeId,
            careTypeCode,
            amount: Formatter.number(amount) || 0
          })
        }
      }
    }
  }
  return roomCharges
}

const cleanCareTypesModel = careTypesModel => {
  const careTypes = []
  const careTypesEntries = Object.entries(careTypesModel)
  for (let careType of careTypesEntries) {
    const careTypeCode =
      (careType[0] && careType[0].replace('careTypeCode-', '')) || 0
    const amount = careType[1]
    careTypes.push({
      careTypeCode,
      units: Formatter.number(amount) || 0
    })
  }
  return careTypes
}
const models = [
  {
    name: 'description',
    get: value => ({
      description: {
        text: value
      }
    })
  },
  {
    name: 'fees',
    get: fees => ({ fees: cleanFeesModel(fees) })
  },
  {
    name: 'roomCharges',
    get: roomCharges => ({ roomCharges: cleanRoomChargesModel(roomCharges) })
  },
  {
    name: 'careTypes',
    get: careTypes => ({ careTypes: cleanCareTypesModel(careTypes) })
  },
  {
    name: 'attributes',
    get: attributes => ({ attributes })
  },
  {
    name: 'isAllInclusive',
    get: isAllInclusive => isAllInclusive
  }
]
export const handleValidSubmit = (
  { communityId, model },
  cb = () => null
) => () => {
  const config = {
    data: {},
    apiVersion: 1
  }
  models.forEach(schema => {
    const modelData = model[schema.name]
    if (modelData) {
      const dataObj = schema.get(modelData)
      config.data = {
        ...config.data,
        ...dataObj
      }
    }
  })
  const resource = new Resource(
    REST_API_ENDPOINTS.communities,
    config.apiVersion
  )
  resource.putSigned(communityId, config, (err, res) => {
    cb(err, res)
  })
}
const errorsListMap = ['description', 'pricing']
export const handleApiResponse = cb => (err, res) => {
  if (err) console.warn(err)
  const { data, status } = res || (err && err.response) || {}
  const { meta, results, errors } = data || {
    meta: {},
    results: {}
  }
  let errorMessage = (meta && meta.message) || false
  const hasSucceed = !errorMessage && results
  if (hasSucceed && status === 200) {
    ToastNotify.success('Changes Saved')
  } else {
    let apiErrorsDisplayed = false
    if (errors) {
      errorsListMap.forEach(key => {
        const apiErrorMessage = errors.located[key]
        if (apiErrorMessage) {
          ToastNotify.error(apiErrorMessage)
          apiErrorsDisplayed = true
        }
      })
    }
    if (!apiErrorsDisplayed) {
      ToastNotify.error(
        errorMessage ||
          'We were not able to save your changes. Please try again in a few minutes.'
      )
    }
  }
  const state = {
    [STATE_FORM_REQUEST_STATUS]: false,
    [STATE_FORM_REQUEST_ERROR]: errors,
    [STATE_FORM_REQUEST_SUCCEED]: hasSucceed
  }
  if (hasSucceed) {
    state[STATE_COMMUNITY] = data
  }
  cb(state)
}

export const setPendingChanges = (ctx, status = true) =>
  ctx.setState({ [STATE_COMMUNITY_HAS_PENDING_CHANGES]: status })

export const showConfirmationModal = ctx =>
  ctx.setState({ [STATE_SHOW_CONFIRM_PENDING_CHANGES]: true })

export const hideConfirmationModal = ctx =>
  ctx.setState({
    [STATE_SHOW_CONFIRM_PENDING_CHANGES]: false,
    [STATE_COMMUNITY_HAS_PENDING_CHANGES]: false
  })
