import React from 'react'
import cx from 'classnames'
import { string, node } from 'prop-types'

const Fields = ({ children, className }) => (
  <div className={cx('di-f', 'wi-100', 'flwr-w', className)}>{children}</div>
)

Fields.propTypes = {
  children: node,
  className: string
}
Fields.defaultProps = {
  children: null,
  className: ''
}

export default Fields
