import React from 'react'
import { object, func, bool } from 'prop-types'

// modules
import formatter from '@modules/formatter'

// components
import Breadcrumbs from '@components/Breadcrumbs'
import Chip from '@components/Chip'
import ChipGroup from '@components/ChipGroup'
import Logo from '../Logo'

const specsMap = ['division', 'region', 'name']

function applyBoldCommunity(text, shouldWrapLast) {
  return shouldWrapLast ? <div className="may-1">{text}</div> : text
}

const SpecsSidebar = ({ community, onNewLogoAdded, readOnly }) => {  
  const breadcrumbs = []

  specsMap.forEach((specName, index) => {
    const specValue = community[specName] || null
    specValue &&
      'N/A' !== specValue &&
      breadcrumbs.push({
        text: applyBoldCommunity(specValue, index === specsMap.length - 1),
        link: '/'
      })
  })

  return (
    <section>
      <Logo
        community={community}
        onNewLogoAdded={onNewLogoAdded}
        readOnly={readOnly}
      />
      
      <div className="fosi-2 mat-4">
        <Breadcrumbs
          links={breadcrumbs}
          style={{ fontSize: '0.9rem' }}
          shouldWrap={breadcrumbs.length > 2}
        />

        <span className="co-gray-3">{community.address}</span>
        <br />
        <a href={`tel:${community.phone}`}>
          {formatter.phone(community.phone)}
        </a>
      </div>

      <ChipGroup className="pay-3">
        {community.fees.map((item, index) => (
          <Chip key={index} canChangeState={false}>
            {item.description}
          </Chip>
        ))}
      </ChipGroup>

      <span className="co-gray-3 fosi-2">
        Last Updated {formatter.date(community.dateUpdated)}
      </span>
    </section>
  )
}

SpecsSidebar.propTypes = {
  community: object.isRequired,
  readOnly: bool,
  onNewLogoAdded: func
}

SpecsSidebar.defaultProps = {
  readOnly: false,
  onNewLogoAdded: () => null
}

export default SpecsSidebar
