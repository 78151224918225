import React, { useState } from 'react'
import { arrayOf, shape, bool } from 'prop-types'

// components
import GroupFields, {
  Header,
  Box,
  Fields,
  Field
} from '@compositions/GroupFields'
import NumericTextField from '@components/NumericTextField'
import { CheckboxWithFormsy as Checkbox } from '@components/Checkbox'

const FeesTable = ({ fees, isAllInclusive, readOnly }) => {
  const [disabled, setDisabled] = useState(isAllInclusive)
  return (
    <>
      <div className="di-f pax-4">
        <Checkbox
          id="isAllInclusive"
          onChange={(_, value) => !readOnly && setDisabled(Boolean(value))}
          title="Prices are all inclusive"
          name="isAllInclusive[isAllInclusive]"
          defaultValue={disabled}
          disabled={readOnly}
        />
      </div>
      <div className="may-4">
        <GroupFields>
          <h2 className="di-f alit-c juco-sb mab-2">Fees</h2>
          {fees.map((fee, key) => (
            <Box key={key}>
              <Header>
                <div>
                  {fee.name}
                  <span className="fowe-n co-gray-3 mal-1">
                    ({fee.defaultPricingDuration})
                  </span>
                </div>
              </Header>
              <Fields>
                {fee.careTypes &&
                  fee.careTypes.map((careType, careTypeKey) => (
                    <Field
                      order={careType.order}
                      key={careTypeKey}
                      label={careType.description}
                    >
                      {careType.isFake ? (
                        <NumericTextField
                          name="NA"
                          className="wi-100"
                          disabled
                        />
                      ) : (
                        <NumericTextField
                          className="wi-100"
                          name={`fees[feeTypeId-${fee.id}][careTypeCode-${careType.code}]`}
                          placeholder="None"
                          value={careType.amount}
                          readOnly={disabled || readOnly}
                          disabled={disabled}
                        />
                      )}
                    </Field>
                  ))}
              </Fields>
            </Box>
          ))}
        </GroupFields>
      </div>
    </>
  )
}

FeesTable.propTypes = {
  fees: arrayOf(shape({})),
  isAllInclusive: bool,
  readOnly: bool
}

FeesTable.defaultProps = {
  fees: [],
  isAllInclusive: false,
  readOnly: false
}

export default FeesTable
