import { Collection, Resource } from '@modules/rest-api'
import { REST_API_ENDPOINTS, API_VERSION } from '@modules/constants'

export default class CoreImage {
  static uploadSigned(data, config, cb = () => null) {
    const collection = new Collection(REST_API_ENDPOINTS.coreImages, API_VERSION)
    collection.createSignedFile(data, config, cb)
  }

  static deleteSigned(config, cb = () => null) {
    const resource = new Resource(REST_API_ENDPOINTS.coreImages, API_VERSION)
    resource.deleteSigned('', config, cb)
  }

  static readResourceSigned = (id, config, cb = () => null) => 
    new Resource(REST_API_ENDPOINTS.coreImages, API_VERSION)
    .readSigned(id, config, cb)

  static readLogoSigned(id, config = {}, cb = () => null) {
    const configParams = config.params || {}
    const overloadedParams = {status: 2, ...configParams, isLogo: 1, includeLarge: 1}
    CoreImage.readResourceSigned(id, {...config, params: overloadedParams}, cb)
  }
}
