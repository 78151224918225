import React from 'react'
import cx from 'classnames'
import { any, array, shape, func, number, string } from 'prop-types'

// components
import Icon from '@components/Icon'
import Avatar from '@components/Avatar'
import Chart from '@components/Chart'
import FeatureToggle from '@components/FeatureToggle'
import FlatButton from '@components/FlatButton'

// modules
import Router from '@modules/router'
import Formatter from '@modules/formatter'
import Copy from '@modules/copy'
import Cloudinary from '@modules/cloudinary'
import { FINANCE_ROLE } from '@modules/constants'

//hooks
import useIsImpersonator from '@hooks/useIsImpersonator'

// styles
import styles from './_.module.scss'

import { SORTBY_COMMUNITY_NAME, COLUMNS } from './constants'
import { SORT_ASC, SORT_DESC } from '../../modules/constants'

const classes = {
  ...styles,
  wrapper: 'di-f',
  columnHeaders: cx('di-f-p', 'di-n-po', 'co-gray-4'),
  columnHeader: cx('fosi-1', 'di-f'),
  columnHeaderActive: cx('co-black', 'fowe-b')
}

const NoResults = () => <div className="ma-2">{Copy.HTTP_204}</div>

const SortingArrow = ({ sortOrder }) => (
  <Icon
    style={sortOrder === SORT_ASC ? { transform: 'rotate(180deg)' } : {}}
    name="arrow-up"
    size={8}
  />
)

SortingArrow.propTypes = {
  sortOrder: number
}

SortingArrow.defaultProps = {
  sortOrder: SORT_ASC
}

const ColumnShape = shape({
  sortColumn: number,
  text: string,
  classNamePointer: string
})

const SyntheticColumnHeader = ({
  children,
  onClick,
  sortColumn,
  sortOrder,
  className,
  column
}) => {
  let sortOrderInverse = sortOrder
  let isActive = false
  if (column.sortColumn === sortColumn) {
    isActive = true
    sortOrderInverse = sortOrder === SORT_ASC ? SORT_DESC : SORT_ASC
  }
  return (
    <div
      onClick={() =>
        column.isFilter ? onClick(column.sortColumn, sortOrderInverse) : null
      }
      className={cx(
        classes.column,
        classes.columnHeader,
        classes[column.classNamePointer] || '',
        className,
        `${column.isFilter ? 'cu-p' : 'cu-na'}`,
        { [classes.columnHeaderActive]: isActive },
        `qa-table-header__action--sort-${column.classNamePointer}`
      )}
      role="columnheader"
    >
      {column.text} {children}
      {sortColumn === column.sortColumn && column.isFilter && (
        <SortingArrow sortOrder={sortOrder} />
      )}
    </div>
  )
}

SyntheticColumnHeader.propTypes = {
  column: ColumnShape.isRequired,
  children: any,
  className: string,
  sortColumn: number,
  sortOrder: number,
  onClick: func
}

SyntheticColumnHeader.defaultProps = {
  onClick: () => null,
  className: '',
  children: null,
  sortColumn: SORTBY_COMMUNITY_NAME,
  sortOrder: SORT_ASC,
  column: {}
}

const SyntheticColumnHeaderSet = ({
  items,
  onSort: onClick,
  sortColumn,
  sortOrder,
  wrapperClassName
}) => {
  return (
    <div className={cx(classes.wrapper, wrapperClassName)}>
      {items.map((item, index) => (
        <SyntheticColumnHeader
          key={index}
          column={item}
          onClick={onClick}
          sortColumn={sortColumn}
          sortOrder={sortOrder}
        />
      ))}
    </div>
  )
}

SyntheticColumnHeaderSet.propTypes = {
  items: array,
  sortColumn: number,
  sortOrder: number,
  wrapperClassName: any,
  onSort: func
}

SyntheticColumnHeaderSet.defaultProps = {
  items: [],
  sortOrder: SORT_ASC,
  sortColumn: SORTBY_COMMUNITY_NAME,
  wrapperClassName: null,
  onSort: () => null
}

const Table = ({ communities, items, className, ...rest }) => {
  const isImpersonator = useIsImpersonator()
  const { roles } = rest
  const isFinance = roles.includes(FINANCE_ROLE)

  return (
    <div
      className={cx(
        classes.tableContainer,
        className,
        'mal-a',
        'mar-a',
        'qa-table'
      )}
      role="table"
    >
      <div
        className={cx(
          'di-f',
          'fldi-r',
          'flwr-nw',
          'pay-2',
          classes.tableRow,
          classes.columnHeaders,
          'qa-table-headers'
        )}
        role="row"
      >
        <div className={cx(classes.wrapper, classes.picture)}>
          <SyntheticColumnHeader {...rest} />
        </div>

        <div className={cx(classes.wrapper, classes.attributes)}>
          <SyntheticColumnHeaderSet
            items={COLUMNS.slice(0, 2)}
            wrapperClassName={classes.nameContact}
            {...rest}
          />
          <SyntheticColumnHeaderSet
            items={COLUMNS.slice(2, 5)}
            wrapperClassName={classes.addressDivisionRegion}
            {...rest}
          />
          <SyntheticColumnHeaderSet
            items={COLUMNS.slice(5, 6)}
            wrapperClassName={classes.dateUpdated}
            {...rest}
          />
        </div>

        <SyntheticColumnHeaderSet
          items={COLUMNS.slice(6, 7)}
          wrapperClassName={classes.score}
          {...rest}
        />
        <FeatureToggle name="epayUrl">
          {isFinance ? (
            <SyntheticColumnHeaderSet
              items={COLUMNS.slice(7, 8)}
              wrapperClassName={classes.invoices}
              {...rest}
            />
          ) : null}
        </FeatureToggle>
      </div>

      {items.length ? (
        items.map(item => {
          const {
            id,
            name,
            contact,
            address,
            division,
            region,
            dateUpdated,
            picture,
            epayUrl
          } = item
          const url = `/communities/${id}`
          const key = `${id}-${name}`
          const flooredScore = Math.floor(
            item.score.values.reduce((acc, obj) => acc + obj.value, 0)
          )
          const ceiledScore = flooredScore >= 99 ? 100 : flooredScore

          const defaultInvoiceClasses = [
            classes.invoiceButton,
            'co-primary-3',
            'di-f',
            'fldi-r'
          ]

          const invoiceClasses =
            !epayUrl || epayUrl === '' || isImpersonator
              ? [classes.linkDisabled, ...defaultInvoiceClasses]
              : defaultInvoiceClasses

          return (
            <div
              key={key}
              className={cx(
                'di-f',
                'fldi-r',
                'flwr-nw',
                'fosi-2',
                'pay-2',
                classes.tableRow
              )}
              role="row"
            >
              <div
                className={cx('di-f', 'cu-p', classes.wrapperRow)}
                onClick={() => Router.go(url, '_self')}
              >
                <div className={cx(classes.wrapper, classes.picture, 'juco-c')}>
                  <div className={cx(classes.column)} role="cell">
                    {picture ? (
                      <div
                        className={styles.pictureImage}
                        style={{
                          backgroundImage: `url(${Cloudinary.tranformImage(
                            item.picture,
                            'w_360'
                          )})`
                        }}
                        title={`Thumbnail photo of ${item.name} property`}
                      />
                    ) : (
                      <Avatar.Contained iconName="gallery" size="md" />
                    )}
                  </div>
                </div>
                <div className={cx(classes.wrapper, classes.attributes)}>
                  <div className={cx(classes.wrapper, classes.nameContact)}>
                    <div
                      className={cx(classes.column, classes.name)}
                      role="cell"
                    >
                      {name}
                    </div>

                    <div
                      className={cx(classes.column, classes.contact)}
                      role="cell"
                    >
                      {contact.firstName} {contact.lastName}
                    </div>
                  </div>

                  <div
                    className={cx(
                      classes.wrapper,
                      classes.addressDivisionRegion
                    )}
                  >
                    <div
                      className={cx(classes.column, classes.address)}
                      role="cell"
                    >
                      {address}
                    </div>

                    <div
                      className={cx(classes.column, classes.division)}
                      role="cell"
                    >
                      {division}
                    </div>

                    <div
                      className={cx(classes.column, classes.region)}
                      role="cell"
                    >
                      {region}
                    </div>
                  </div>

                  <div className={cx(classes.wrapper, classes.dateUpdated)}>
                    <div
                      className={cx(classes.column, classes.dateUpdated)}
                      role="cell"
                    >
                      {Formatter.date(dateUpdated, 'MM/DD/YYYY h:mma')}
                    </div>
                  </div>
                </div>
                <div className={cx(classes.wrapper, classes.score)}>
                  <div
                    className={cx(classes.column, classes.score)}
                    role="cell"
                  >
                    <Chart.Donut size={40} progress={ceiledScore} />
                  </div>
                </div>
              </div>
              <FeatureToggle name="epayUrl">
                {isFinance ? (
                  <div className={cx(classes.wrapper, classes.invoices)}>
                    <div className={cx(classes.column)} role="cell">
                      <FlatButton
                        className={cx(...invoiceClasses)}
                        onClick={e => {
                          isFinance && epayUrl && !isImpersonator
                            ? Router.go(epayUrl)
                            : e.preventDefault()
                        }}
                      >
                        Details
                      </FlatButton>
                    </div>
                  </div>
                ) : null}
              </FeatureToggle>
            </div>
          )
        })
      ) : (
        <NoResults />
      )}
    </div>
  )
}

Table.propTypes = {
  className: string,
  communities: array,
  items: array,
  sortColumn: number,
  sortOrder: number,
  onSort: func
}

Table.defaultProps = {
  communities: [],
  items: [],
  sortOrder: SORT_ASC,
  sortColumn: SORTBY_COMMUNITY_NAME,
  onSort: () => null,
  className: ''
}

export default Table
