// modules
import datejs from '@modules/datejs'
import { Collection, Resource } from '@modules/rest-api'
import { REST_API_ENDPOINTS } from '@modules/constants'
import {
  STATE_EVENTS_REQUEST_STATUS,
  STATE_EVENTS_REQUEST_ERROR,
  STATE_EVENTS_REQUEST_SUCCEED,
  ITEMS_PER_PAGE
} from '../constants'

import { DATE_FORMAT } from '@components/constants'
import { ToastNotify } from '@compositions/Toast'

const apiVersion = 1

const resourceConfig = {
  apiVersion
}

const eventResource = new Resource(
  REST_API_ENDPOINTS.communityEvents,
  apiVersion
)

const eventsCollection = new Collection(
  REST_API_ENDPOINTS.communityEvents,
  apiVersion
)

export const fetchEvents = (communityId, params, ctx, cb) => {
  ctx.setState({
    [STATE_EVENTS_REQUEST_STATUS]: true
  })

  const config = {
    params: {
      page: 1,
      itemsPerPage: ITEMS_PER_PAGE,
      ...params,
      communityId,
      startDate: datejs().format(DATE_FORMAT)
    },
    ...resourceConfig
  }

  eventsCollection.readSigned(config, (err, res) => {
    ctx.setState({
      [STATE_EVENTS_REQUEST_ERROR]: err,
      [STATE_EVENTS_REQUEST_SUCCEED]: (res && res.data) || [],
      [STATE_EVENTS_REQUEST_STATUS]: false
    })

    cb && cb(err, res)
  })
}

export const handleEventDelete = ctx => eventId => {
  eventResource.deleteSigned(eventId, resourceConfig, err => {
    if (err) {
      console.warn(err)
      ToastNotify.error('There was an error while deleting the event')
    } else {
      ToastNotify.success('Your event has been deleted')
    }
    let payload = ctx.state[STATE_EVENTS_REQUEST_SUCCEED]
    const newResults = payload.results.slice()
    const deleteIndex = newResults.findIndex(
      item => item && item.eventId === eventId
    )
    deleteIndex !== -1 && delete newResults[deleteIndex]

    ctx.setState({
      [STATE_EVENTS_REQUEST_SUCCEED]: { ...payload, results: newResults }
    })
  })
}

export const handleEventCreate = (model, cb) => {
  eventsCollection.createSigned(model, resourceConfig, (err, res) => {
    if (err) {
      err.response && cb(err.response.data)
      ToastNotify.error('There was an error while creating the event')
    } else {
      ToastNotify.success('Your event has been created')
      cb(null, res.data)
    }
  })
}

export const handleEventUpdate = (eventId, model, cb) => {
  eventResource.putSigned(
    eventId,
    { ...resourceConfig, data: model },
    (err, res) => {
      if (err) {
        err.response && cb(err.response.data)
        ToastNotify.error('There was an error while updating the event')
      } else {
        ToastNotify.success('Your event has been updated')
        cb(null, res.data)
      }
    }
  )
}
