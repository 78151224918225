import React, { Component } from 'react'
import { bool, func, node, object, string } from 'prop-types'
import withThemeClassNames from '../../hoc/withThemeClassNames'
import cx from 'classnames'

// styles
import styles from './_.module.scss'

const STATE_IS_ACTIVE = 'isActive'

class Chip extends Component {
  constructor(props) {
    super(props)
    this.state = {
      [STATE_IS_ACTIVE]: props.isActive
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.isActive !== state.isActive) {
      return {
        [STATE_IS_ACTIVE]: state.isActive
      }
    }
    return null
  }

  render() {
    const {
      canChangeState,
      children,
      onClick,
      themeClassNames,
      inactiveClass,
      className,
      activeStyle,
      inactiveStyle
    } = this.props
    const isActive = this.state[STATE_IS_ACTIVE]

    return (
      <div
        className={cx(
          'pax-2',
          'pay-1',
          'fosi-2',
          'no',
          'bost-s',
          'bowi-0',
          'di-f',
          'alit-c',
          styles.chip,
          isActive
            ? `${themeClassNames.backgroundColor} is-active`
            : inactiveClass
            ? inactiveClass
            : 'baco-gray-1',
          isActive ? 'co-gray-4' : null,
          className
        )}
        onClick={() => {
          if (canChangeState) {
            this.setState({ [STATE_IS_ACTIVE]: !isActive })
            onClick(!isActive)
          }
        }}
        style={isActive ? activeStyle : inactiveStyle}
      >
        {children}
      </div>
    )
  }
}

Chip.propTypes = {
  activeStyle: object,
  canChangeState: bool,
  children: node,
  className: string,
  inactiveClass: string,
  inactiveStyle: object,
  isActive: bool,
  themeClassNames: object,
  onClick: func
}

Chip.defaultProps = {
  activeStyle: {},
  inactiveStyle: {},
  canChangeState: true,
  isActive: false,
  onClick: () => null,
  children: null,
  className: '',
  inactiveClass: '',
  themeClassNames: {}
}

export default withThemeClassNames(Chip)
