import React from 'react'
import cx from 'classnames'
import { string, node, oneOf } from 'prop-types'

// styles
import styles from './_.module.scss'

// hoc
import withOrder from '@hoc/withOrder'

const COLUMNS_STYLE = {
  4: 'inputFieldFourColumns',
  5: 'inputFieldFiveColumns'
}
const Field = ({ label, columns, children, className }) => (
  <div
    className={cx(
      'di-f',
      'fldi-c',
      'par-4',
      'mat-2',
      styles[COLUMNS_STYLE[columns]],
      className
    )}
  >
    <div className="co-gray-4 fosi-1">{label}</div>
    <div>{children}</div>
  </div>
)

Field.propTypes = {
  label: node,
  children: node,
  className: string,
  columns: oneOf([4, 5])
}

Field.defaultProps = {
  label: null,
  children: null,
  className: '',
  columns: 4
}

export default withOrder(Field)
