import React, { useState } from 'react'
import { any, func } from 'prop-types'

// components
import Modal, { SMALL_MODAL } from '@components/Modal'
import RemoveBody from './components/RemoveBody'
import RemoveFooter from './components/RemoveFooter'
import WarningBody from './components/WarningBody'
import WarningFooter from './components/WarningFooter'
import LoadingMessage from '@components/EditEmployeeForm/components/LoadingMessage'

const RemoveEmployee = ({ employee, closeModal, onConfirm }) => {
  const [removing, setRemoving] = useState(false)
  const isPrimarycontact = employee.isPrimary || false
  return (
    <Modal
      className="po-r"
      size={SMALL_MODAL}
      footer={(exit) => {
        return isPrimarycontact
        ? <WarningFooter exit={exit} />
        : <RemoveFooter removing={removing} exit={exit} setRemoving={setRemoving} onConfirm={onConfirm} employee={employee} />
      }}
      onToggleActive={() => closeModal()}
    >
      {removing ? (
        <LoadingMessage message="Removing employee please wait..." />
      ) : isPrimarycontact ? (
        <WarningBody />
      ) : (
        <RemoveBody employee={employee}/>
      )}
    </Modal>
  )
}

RemoveEmployee.propTypes = {
  employee: any.isRequired,
  closeModal: func,
  onConfirm: func
}
RemoveEmployee.defaultProps = {
  closeModal: () => null,
  onConfirm: () => null
}

export default RemoveEmployee
