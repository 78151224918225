import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import { number, string, func, shape, bool, array, any } from 'prop-types'

// components
import AdvisorCard from '@components/AdvisorCard'
import Label from '@components/Label'
import TextLink from '@components/TextLink'
import Button from '@components/Button'
import Icon from '@components/Icon'
import FeatureToggle from '@components/FeatureToggle'

// modules
import { Collection } from '@modules/rest-api'
import { REST_API_ENDPOINTS, API_VERSION } from '@modules/constants'

// styles
import style from './_.module.scss'

const ITEMS_PER_PAGE = 10
const fetchMore = (params = {}, done = () => null) => {
  const config = {
    apiVersion: API_VERSION,
    params: {
      itemsPerPage: ITEMS_PER_PAGE,
      ...params
    }
  }
  const employees = new Collection(
    REST_API_ENDPOINTS.personnel,
    config.apiVersion
  )
  employees.readSigned(config, (err, res) => {
    if (err) return done(err)
    const { data } = res || {}
    const { results, meta } = data || { results: [] }
    done(null, results, meta)
  })
}

const PersonnelOptions = ({
  communityId,
  readOnly,
  rolesDataSource,
  communityName,
  updatedEmployee,
  onEmployeeClick,
  onAddEmployeeClick,
  onRemoveEmployeeClick
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [last, setLast] = useState(false)
  const [page, setPage] = useState(1)
  const [list, setList] = useState([])

  function getRoleName(id) {
    const { error, data, isLoading } = rolesDataSource || {}
    if (isLoading || error) return null
    const roleFound = data.find(role => role.id === id)
    return (roleFound && roleFound.value) || ''
  }
  useEffect(() => {
    setIsLoading(true)
    fetchMore({ page, communityId }, (error, append, pagination) => {
      setIsLoading(false)
      if (error) setErrorMessage(error)
      else {
        setList(prevState => {
          let newList = append
          if (page > 1) {
            newList = [...prevState, ...append]
          }
          if (pagination.totalItems === newList.length || append.length === 0) {
            setLast(true)
          }
          return newList
        })
      }
    })
  }, [page, communityId])

  useEffect(() => {
    if (updatedEmployee) {
      if (updatedEmployee.hasOwnProperty('employmentId')) {
        const resetPrimaryContact = Boolean(updatedEmployee.isPrimary)
        setList(prevState => {
          const prevStateCopy = prevState.slice()
          return prevStateCopy
            .map(employee =>
              employee.employmentId === updatedEmployee.employmentId
                ? updatedEmployee
                : {
                    ...employee,
                    isPrimary: resetPrimaryContact ? false : employee.isPrimary
                  }
            )
            .sort((a, b) => {
              if (a.firstName < b.firstName) return -1
              if (a.firstName > b.firstName) return 1
              if (a.firstName == b.firstName) {
                if (a.lastName < b.lastName) return -1
                if (a.lastName > b.lastName) return 1
              }
              return 0
            })
            .sort(a => (a.isPrimary ? -1 : 1))
        })
      }
    }
  }, [updatedEmployee])

  return errorMessage ? (
    'There was an error loading the employees'
  ) : (
    <div className="di-f fldi-c">
      <div className="di-f fldi-r juco-sb mat-4 pat-2">
        <h3 className="fowe-b co-black">Personnel Options</h3>
        {!readOnly && (
          <FeatureToggle name="employeeCreate">
            <TextLink
              className="fosi-2"
              href="/#"
              onClick={event => {
                event.preventDefault()
                onAddEmployeeClick()
              }}
            >
              Add Employee
            </TextLink>
          </FeatureToggle>
        )}
      </div>
      <div
        className={cx('di-f', 'fldi-c', style.employeesList, {
          [style.showMore]: list.length > 10
        })}
      >
        {list.map(
          (employee, index) =>
            !employee.endDate && (
              <div
                key={index}
                className={cx(
                  'di-f',
                  'fldi-r',
                  'juco-sb',
                  style.advisorContainer
                )}
              >
                <AdvisorCard
                  avatarConfig={{
                    containerWidth: 'auto',
                    className: cx('bora-round', {
                      [style.avatarDefault]: !employee.picture
                    })
                  }}
                  config={{
                    advisorNameColor: 'co-secondary-3',
                    advisorNameSpacing: 'par-1'
                  }}
                  displayTitle={false}
                  iconName="person"
                  name={`${employee.firstName} ${employee.lastName}`}
                  content={getRoleName(employee.roleId)}
                  className={readOnly ? "cu-d" : "cu-p"}
                  onClick={() => !readOnly && onEmployeeClick(employee)}
                  chipComponent={
                    employee.hasReferralAlert && (
                      <Label
                        theme="primary"
                        themeLevel={3}
                        className={cx('co-white', style.label)}
                      >
                        Alerts
                      </Label>
                    )
                  }
                />
                {!readOnly && (
                  <FeatureToggle name="employeeDelete">
                    <div className={cx('di-f', 'alit-c', style.removeButton)}>
                      <Button
                        className="juco-c"
                        palette="flat"
                        type="button"
                        onClick={() => onRemoveEmployeeClick(employee)}
                        title="Remove Employee"
                      >
                        <Icon name="trash" size={20} />
                      </Button>
                    </div>
                  </FeatureToggle>
                )}
              </div>
            )
        )}
        {isLoading ? (
          <div className="pab-2 pal-1">Loading...</div>
        ) : last ? (
          page > 1 && (
            <span className="co-gray-4 teal-l fosi-1 pab-4 pal-1">
              No more employees
            </span>
          )
        ) : (
          <a
            href="/#"
            className="pab-2 pal-1"
            onClick={event => {
              event.preventDefault()
              setPage(page + 1)
            }}
          >
            Load more
          </a>
        )}
      </div>
      <div className="di-f fldi-c fosi-2 mat-4">
        <span className="co-gray-3">Looking for leads?</span>
        <TextLink to={`/leads?communities=${communityId}`}>
          View leads for {communityName}
        </TextLink>
      </div>
    </div>
  )
}

PersonnelOptions.propTypes = {
  communityId: number,
  readOnly: bool,
  communityName: string,
  rolesDataSource: shape({
    isLoading: bool.isRequired,
    data: array.isRequired,
    error: any
  }),
  updatedEmployee: any,
  onEmployeeClick: func,
  onAddEmployeeClick: func,
  onRemoveEmployeeClick: func
}

PersonnelOptions.defaultProps = {
  communityId: null,
  readOnly: false,
  communityName: null,
  rolesDataSource: {
    isLoading: false,
    data: []
  },
  updatedEmployee: null,
  onEmployeeClick: () => null,
  onAddEmployeeClick: () => null,
  onRemoveEmployeeClick: () => null
}

export default PersonnelOptions
