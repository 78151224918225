import React, { Component } from 'react'
import { any, string, object } from 'prop-types'
import cx from 'classnames'

// components
import TextField from '@components/TextField'
import TextArea from '@components/TextArea'

// styles
import styles from './_.module.scss'

// helpers
const TextFieldWithLabel = ({ label, Constructor, ...rest }) => (
  <label>
    <div className="mat-2 fosi-1 co-gray-3">{label}</div>
    <Constructor {...rest} className="mat-1" palette="white" />
  </label>
)

TextFieldWithLabel.propTypes = {
  label: string.isRequired,
  Constructor: any.isRequired
}

// accessors
const STATE_DATE = 'date'
const STATE_START_DATE = 'startDate'
const STATE_END_DATE = 'endDate'
const STATE_IS_HYDRATED = 'isHydrated'

export default class EventActionPoly extends Component {
  constructor(props) {
    super(props)

    const date = new Date()

    this.state = {
      [STATE_DATE]: date,
      [STATE_START_DATE]: date,
      [STATE_END_DATE]: date,
      [STATE_IS_HYDRATED]: false
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { model } = props
    const { isHydrated } = state
    if (model[STATE_START_DATE] && !isHydrated) {
      return {
        [STATE_DATE]: new Date(model[STATE_START_DATE]),
        [STATE_START_DATE]: new Date(model[STATE_START_DATE]),
        [STATE_END_DATE]: new Date(model[STATE_END_DATE]),
        [STATE_IS_HYDRATED]: true
      }
    }

    return null
  }

  render() {
    const {
      [STATE_DATE]: date,
      [STATE_START_DATE]: startDate,
      [STATE_END_DATE]: endDate
    } = this.state

    const { validationErrors, model } = this.props

    return (
      <>
        What are you experiencing or wondering about that we can help you with?
        <section>
          <TextField.Labeled
            label="* Title"
            name="title"
            Constructor={TextField}
            required
            value={model.title}
          />

          <TextField.Labeled
            label="Location"
            name="location"
            Constructor={TextField}
            value={model.location}
          />

          <section className={cx('di-f', 'juco-sb', styles.halvedSection)}>
            <TextField.Labeled
              label="* Date"
              name="_date"
              Constructor={TextField.DatePicker}
              attrs={{
                onChange: value => {
                  value && this.setState({ [STATE_DATE]: value })
                },
                selected: date,
                value: date
              }}
            />

            <TextField
              name="date"
              value={date.toLocaleString()}
              type="hidden"
              required
            />
          </section>

          <section className={cx('di-f', 'juco-sb', styles.halvedSection)}>
            <TextField.Labeled
              label="* Start time"
              name="_startDate"
              Constructor={TextField.TimePicker}
              errorMessage={validationErrors.startDate}
              attrs={{
                onChange: value => {
                  value && this.setState({ [STATE_START_DATE]: value })
                },
                selected: startDate,
                value: startDate
              }}
            />

            <TextField.Labeled
              label="* End time"
              name="_endDate"
              Constructor={TextField.TimePicker}
              errorMessage={validationErrors.endDate}
              attrs={{
                onChange: value => {
                  value && this.setState({ [STATE_END_DATE]: value })
                },
                selected: endDate,
                value: endDate
              }}
            />
          </section>

          <TextField
            name="startDate"
            value={startDate.toLocaleString()}
            type="hidden"
            required
          />

          <TextField
            name="endDate"
            value={endDate.toLocaleString()}
            type="hidden"
            required
          />

          <TextField.Labeled
            label="* Description"
            name="description"
            Constructor={TextArea}
            required
            value={model.description}
          />

          {model.communityId ? (
            <>
              <TextField
                name="communityId"
                value={model.communityId}
                type="hidden"
                required
              />

              <TextField
                name="eventId"
                value={model.eventId}
                type="hidden"
                required
              />

              <TextField name="_index" value={model._index} type="hidden" />
            </>
          ) : null}
        </section>
      </>
    )
  }
}

EventActionPoly.propTypes = {
  validationErrors: object,
  model: object
}

EventActionPoly.defaultProps = {
  validationErrors: {},
  model: {}
}
