import React, { useEffect, useState } from 'react'
import { shape, number, func, bool } from 'prop-types'

import { ToastNotify } from '@compositions/Toast'
// components
import ImageSelectorButton from '@components/ImageSelectorButton'
import ImageEditor from '@components/ImageEditor'
import ImagesUploadsManager from '@components/ImagesUploadsManager'

// modules
import Cloudinary from '@modules/cloudinary'
import { evalFileViability } from '@modules/file'

const LABEL_COPY = 'Max size 10MB.'

const Logo = ({ community, onNewLogoAdded, readOnly }) => {
  const [logo, setLogo] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [label, setLabel] = useState("Loading your community's logo...")

  const [logoToUpload, setLogoToUpload] = useState(null)
  const [logoError, setLogoError] = useState(null)
  const [transformedLogoMap, setTransformedLogoMap] = useState(new Map())

  useEffect(() => {
    Cloudinary.readLogoSigned(community.id, {}, (err, res) => {
      setIsLoading(false)

      if (err) {
        setError({
          message: `There was an error loading your logo. ${LABEL_COPY}`
        })
      } else {
        const logoImage = res.data.results.length && res.data.results[0]
        setLogo(logoImage ? { imageUrl: logoImage.secureUrl } : null)

        setError(null)
        setLabel(LABEL_COPY)
      }
    })
  }, [community])

  logoError && ToastNotify.error(logoError)

  return (
    <>
      <ImageSelectorButton
        isMultiple={false}
        buttonAttrs={{
          children: `${logo ? 'Update' : 'Upload'} Logo`,
          onClick: () => null,
          disabled: isLoading
        }}
        label={error ? error.message : label}
        assetToMigrate={logo}
        onFilesSelect={selectedFile => {
          if (evalFileViability(selectedFile)) {
            setLogoToUpload(selectedFile)
            logoError && setLogoError(null)
          } else {
            setLogoError(
              `The file you've uploaded is too large. Please choose an image that is less than 10MB in size.`
            )
          }
        }}
        readOnly={readOnly}
      />

      {logoToUpload && (
        <ImageEditor
          isCanvas={true}
          hasFeatureCheckbox={false}
          imageFile={logoToUpload}
          onCancel={() => setLogoToUpload(null)}
          onSave={(imageFile, blob, dataUrl, model) => {
            const transformedFile = new File([blob], imageFile.name)
            const transformedFileAsDict = {
              model: { ...model, communityId: community.id, isLogo: 1 },
              file: transformedFile,
              fileAsBlob: blob,
              fileAsDataUrl: dataUrl
            }

            const transformedFileUUID =
              transformedFile.name + transformedFile.size
            const transformedFileMap = new Map([
              [transformedFileUUID, transformedFileAsDict]
            ])

            setTransformedLogoMap(transformedFileMap)
            setLogoToUpload(null)
          }}
        />
      )}
      {transformedLogoMap.size > 0 && (
        <ImagesUploadsManager
          filesDictMap={transformedLogoMap}
          onError={() => {
            setLogoError(
              'There was an error uploading your image. Please try again.'
            )
          }}
          onRepeated={file => {
            const transformedLogoMapCopy = new Map(transformedLogoMap)
            transformedLogoMapCopy.delete(file.name + file.size)
            setTransformedLogoMap(transformedLogoMapCopy)
          }}
          onProgress={(_, progress) => {
            if (progress === 50)
              ToastNotify.info(`Uploading your logo, please wait`)
          }}
          onSuccess={(_, response) => {
            const cloudinaryPayload = response.data.results
            onNewLogoAdded(cloudinaryPayload)
            ToastNotify.success('Logo successfully uploaded')
            logoError && setLogoError(null)
          }}
        />
      )}
    </>
  )
}

Logo.propTypes = {
  community: shape({ id: number.isRequired }).isRequired,
  readOnly: bool,
  onNewLogoAdded: func
}

Logo.defaultProps = {
  readOnly: false,
  onNewLogoAdded: () => null
}

export default Logo
