import { Collection } from '@modules/rest-api'
import {
  STATE_DATA_REQUEST_STATUS,
  STATE_DATA_REQUEST_ERROR,
  STATE_DATA
} from '../constants'

export const stateUpdateFactory = (err, res) => {
  if (err)
    return {
      [STATE_DATA_REQUEST_STATUS]: false,
      [STATE_DATA_REQUEST_ERROR]: err
    }

  return {
    [STATE_DATA]: res.data,
    [STATE_DATA_REQUEST_STATUS]: false,
    [STATE_DATA_REQUEST_ERROR]: null
  }
}

export const fetchCommunities = (params = {}, ctx = {}, done = () => null) => {
  ctx.setState({
    [STATE_DATA_REQUEST_STATUS]: true,
    [STATE_DATA_REQUEST_ERROR]: null
  })

  const communities = new Collection('communities', 1)
  communities.readSigned(params, (err, res) => {
    ctx.setState(state => {
      return stateUpdateFactory(err, res)
    })
    done()
  })
}

export const getSearchParamAsArray = param => {
  const typeOfParam = typeof param

  if (typeOfParam === 'string') {
    return [param]
  } else if (typeOfParam === 'object') {
    return param
  }

  return []
}
