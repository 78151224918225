import React from 'react'
import cx from 'classnames'
import { string, node } from 'prop-types'

const Header = ({ children, headerAction, className }) => (
  <div
    className={cx(
      'alit-c',
      'di-f',
      'flwr-w',
      'co-black',
      'fowe-b',
      'juco-sb',
      'wi-100',
      className
    )}
  >
    {children}
    {headerAction}
  </div>
)

Header.propTypes = {
  className: string,
  children: node,
  headerAction: node
}

Header.defaultProps = {
  className: '',
  children: null,
  headerAction: null
}

export default Header
