import React, { useState } from 'react'
import cx from 'classnames'
import datejs from '@modules/datejs'

// components
import Icon from '@components/Icon'

// styles
import styles from './_.module.scss'
import { array, string, func, bool } from 'prop-types'

const ButtonIcon = ({ icon, className, onClick }) => (
  <div className="mat-4 cu-p" onClick={onClick}>
    <Icon name={icon} className={className} size={20} />
  </div>
)

ButtonIcon.propTypes = {
  icon: string.isRequired,
  className: string,
  onClick: func
}

ButtonIcon.defaultProps = {
  className: '',
  onClick: () => null
}

const List = ({ events, readOnly, onDelete, onEdit }) => {
  const [deleteId, setDeleteId] = useState(null)
  return (
    <div className="di-f fldi-c">
      <div className="di-f fldi-c mat-4">
        {events.length > 0 ? (
          events.map((event, index) => {
            event._index = index
            const { eventId, startDate, endDate } = event
            if (eventId === deleteId) return null
            const eventStartDateTime = datejs(startDate)
            const eventDate = eventStartDateTime.format('dddd, MMMM Do YYYY')
            const eventStartTime = eventStartDateTime.format('h:mm A')
            const eventEndTime = datejs(endDate || startDate).format('h:mm A')
            return (
              <div
                key={eventId}
                className={cx(
                  'di-f',
                  'fldi-c',
                  'bowi-1',
                  'bost-s',
                  'boco-gray-2',
                  'pa-4',
                  'mab-2',
                  'bora-1',
                  'fosi-2',
                  'po-r',
                  'cu-p',
                  styles.event
                )}
              >
                {!readOnly && (
                  <div className={cx('po-a', 'di-f', styles.actionsGroup)}>
                    <ButtonIcon
                      className="co-primary-3"
                      icon="pencil"
                      onClick={() => onEdit(event)}
                    />
                    <ButtonIcon
                      className="co-gray-2"
                      icon="trash"
                      onClick={() => {
                        if (deleteId !== eventId) {
                          setDeleteId(eventId)
                          onDelete(eventId)
                        }
                      }}
                    />
                  </div>
                )}
                <h3 className="fowe-b mab-1 co-black">{event.title}</h3>
                <p className="mab-2">{event.location}</p>
                <h4 className="fowe-b co-black">{eventDate}</h4>
                <p className="mab-2 co-black">
                  {eventStartTime} - {eventEndTime}
                </p>
                <p>{event.description}</p>
              </div>
            )
          })
        ) : (
          <div className="bowi-1 bost-s boco-gray-2 pa-4 mab-2 bora-1 fosi-2 po-r">
            You have not scheduled any events.
          </div>
        )}
      </div>
    </div>
  )
}

List.propTypes = {
  events: array,
  readOnly: bool,
  onEdit: func,
  onDelete: func
}
List.defaultProps = {
  events: [],
  readOnly: false,
  onDelete: () => null,
  onEdit: () => null
}

export default List
