import React, { useState, Fragment } from 'react'
import { string, array, number, oneOfType, func, bool } from 'prop-types'

// components
import Radio from '@components/Radio'
import InputNumber from '../InputNumber'

const YesNoInput = ({ tagId, id, name, value, items, readOnly, onChange }) => {
  const [isActive, setIsActive] = useState(parseInt(value) || 0)

  const yesNoOptions = [
    {
      id: 1,
      label: 'Yes',
      value: 1
    },
    {
      id: 2,
      label: 'No',
      value: 0
    }
  ]
  return (
    <div className="di-f fldi-c">
      <div className="di-f fldi-r">
        {yesNoOptions.map((item, index) => (
          <Fragment key={index}>
            <Radio
              id={`${tagId}-${index}`}
              label={item.label}
              name={`${name}[${id}]`}
              checked={isActive === item.value}
              value={parseInt(item.value)}
              initialValue={parseInt(value) || 0}
              className="fosi-2 par-3"
              onChange={evt => {
                setIsActive(parseInt(evt.target.value))
                onChange({ name, id, value: parseInt(evt.target.value) })
              }}
              disabled={readOnly}
            />
          </Fragment>
        ))}
      </div>
      <div className="co-gray-4">
        {items.map((item, index) => (
          <InputNumber
            key={index}
            {...item}
            onChange={onChange}
            disabled={!isActive}
          />
        ))}
      </div>
    </div>
  )
}
YesNoInput.propTypes = {
  id: oneOfType([string, number]).isRequired,
  tagId: oneOfType([string, number]).isRequired,
  name: string.isRequired,
  value: string,
  readOnly: bool,
  items: array,
  onChange: func
}

YesNoInput.defaultProps = {
  value: null,
  readOnly: false,
  items: [],
  onChange: () => null
}

export default YesNoInput
