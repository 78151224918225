import React, { Component } from 'react'
import { array, number, string, bool, func, object } from 'prop-types'
import cx from 'classnames'

// components
import ImagePreviewer from '@components/ImagePreviewer'
import ImageSelectorButton from '@components/ImageSelectorButton'
import ImageEditor from '@components/ImageEditor'
import Icon from '@components/Icon'
import ImagesUploadsManager from '@components/ImagesUploadsManager'
import Loader from '@components/Loader'
import Chip from '@components/Chip'
import { ToastNotify } from '@compositions/Toast'
import { Tabs, Tab } from '@components/Tabs'
import Paginator from '@components/Paginator'

// modules
import Cloudinary from '@modules/cloudinary'
import { IMAGE_STATUSES } from '@modules/constants'
import { evalFileViability } from '@modules/file'

// styles
import styles from './_.module.scss'

const ImagePreviewerItem = props => (
  <li className={cx(styles.item)}>
    <ImagePreviewer {...props} />
  </li>
)

const DecoratedChip = props => (
  <Chip
    {...props}
    isActive={true}
    canChangeState={false}
    className="co-white mar-1"
  />
)

const getSegregatedOverlay = (isFeatured, status) => {
  const featuredPhoto = +isFeatured ? (
    <DecoratedChip theme="primary" canChangeState={false}>
      Featured Photo
    </DecoratedChip>
  ) : null

  const mapper = {
    [IMAGE_STATUSES.pending]: (
      <div className="di-f">
        {featuredPhoto}
        <DecoratedChip theme="primary" canChangeState={false}>
          Pending
        </DecoratedChip>
      </div>
    ),
    [IMAGE_STATUSES.approved]: <div className="di-f">{featuredPhoto}</div>,
    [IMAGE_STATUSES.rejected]: (
      <div className="di-f">
        {featuredPhoto}
        <DecoratedChip theme="orange">Rejected</DecoratedChip>
      </div>
    )
  }

  return mapper[status] || null
}

const Actions = props => (
  <div className="di-f juco-fe wi-100">
    {!props.readOnly && (
      <ImagePreviewer.FlatButton onClick={props.onEdit}>
        <Icon name="pencil" size={16} />
      </ImagePreviewer.FlatButton>
    )}

    {!props.readOnly && (
      <ImagePreviewer.FlatButton onClick={props.onDelete}>
        <Icon name="trash" size={18} />
      </ImagePreviewer.FlatButton>
    )}

    {props.readOnly && (
      <ImagePreviewer.FlatButton onClick={props.onView}>
        <span className="fosi-1">View</span>
      </ImagePreviewer.FlatButton>
    )}
  </div>
)

Actions.propTypes = {
  readOnly: bool,
  onDelete: func,
  onEdit: func,
  onView: func
}

Actions.defaultProps = {
  readOnly: false,
  onDelete: () => null,
  onEdit: () => null,
  onView: () => null
}

const ProgressControl = props => (
  <div className="di-f juco-sb wi-100 alit-c">
    <progress className="mal-2" value={props.progress} max="100" />
    <ImagePreviewer.FlatButton>
      <Icon name="close" size={18} />
    </ImagePreviewer.FlatButton>
  </div>
)

ProgressControl.propTypes = {
  progress: number
}

ProgressControl.defaultProps = {
  progress: 0
}

const ToastMessageThumbnail = ({ message, asset, file }) => (
  <span className={cx('di-f', 'alit-c', styles.thumbnail)}>
    <span className="di-f fldi-c par-2">
      <span>{message}</span>
      {file && (
        <span className={cx('co-secondary-2', styles.filename)}>
          {file.name}
        </span>
      )}
    </span>
    {file && asset && (
      <img
        alt={file.name}
        src={Cloudinary.makeThumbnailFromSecureUrl(asset.secureUrl)}
        className="di-f"
      />
    )}
  </span>
)
ToastMessageThumbnail.propTypes = {
  message: string.isRequired,
  asset: object,
  file: object
}
ToastMessageThumbnail.defaultProps = {
  asset: null,
  file: null
}

const STATE_SELECTED_DATA_URLS_MAP = 'selectedDataUrlsMap'
const STATE_SELECTED_FILES_ARRAY = 'selectedFilesArray'
const STATE_CURRENT_FILE = 'currentFile'
const STATE_UPLOADS_DICT_QUEUE_MAP = 'uploadsDictQueueMap'
const STATE_COMPLETED_FILES_MAP = 'completedFilesMap'
const STATE_COMPLETED_FILES_ARRAY = 'completedFilesArray'
const STATE_UPLOADS_PROGRESS_MAP = 'uploadsProgressMap'
const STATE_COMPLETED_FILES_PUBLIC_IDS_ARRAY = 'completedFilesPublicIdsArray'
const MAX_PHOTOS_PER_PAGE = 8
const MAX_LOGOS_PER_PAGE = 9

class ImagesManager extends Component {
  state = {
    [STATE_UPLOADS_DICT_QUEUE_MAP]: new Map(),
    [STATE_SELECTED_DATA_URLS_MAP]: new Map(),
    [STATE_CURRENT_FILE]: null,
    [STATE_COMPLETED_FILES_MAP]: new Map(),
    [STATE_COMPLETED_FILES_PUBLIC_IDS_ARRAY]: [],
    [STATE_SELECTED_FILES_ARRAY]: [],
    [STATE_UPLOADS_PROGRESS_MAP]: new Map(),
    [STATE_COMPLETED_FILES_ARRAY]: [],
    currentImagesPage: 0,
    currentLogosPage: 0
  }

  moveToNextFile = () => {
    this.setState(state => {
      let selectedFilesArrayCopy = state[STATE_SELECTED_FILES_ARRAY].slice()
      selectedFilesArrayCopy.shift()

      return {
        [STATE_SELECTED_FILES_ARRAY]: selectedFilesArrayCopy
      }
    })
  }

  sliceArrayInGroups = (arr, size) => {
    if (arr.length === 0) {
      return arr
    }
    return [
      arr.slice(0, size),
      ...this.sliceArrayInGroups(arr.slice(size), size)
    ]
  }

  updatePage = (action, page) => {
    const currentPageToUpdate =
      page == 'images' ? 'currentImagesPage' : 'currentLogosPage'

    const currentPageValue =
      action == 'next'
        ? this.state[currentPageToUpdate] + 1
        : this.state[currentPageToUpdate] - 1

    this.setState({
      [currentPageToUpdate]: currentPageValue
    })
  }

  resetCurrentPage = () => {
    this.setState({
      currentImagesPage: 0,
      currentLogosPage: 0
    })
  }

  render() {
    const {
      images,
      communityId,
      className,
      isLoading,
      onImageDelete,
      onImageEdit,
      onImageView,
      onImageUpload,
      readOnly
    } = this.props

    const {
      [STATE_UPLOADS_DICT_QUEUE_MAP]: uploadsDictQueueMap,
      [STATE_SELECTED_DATA_URLS_MAP]: selectedDataUrlsMap,
      [STATE_UPLOADS_PROGRESS_MAP]: uploadsProgressMap
    } = this.state

    const currentFile = this.state[STATE_SELECTED_FILES_ARRAY][0]
    const imagesWithLogoFlagOn = images.filter(item => +item.metadata.isLogo)
    const imagesWithoutLogos = images.filter(item => !+item.metadata.isLogo)

    const imagesWithFeaturedFlagOn = imagesWithoutLogos.filter(
      item => +item.metadata.isFeatured
    )
    const imagesWithFeaturedFlagOff = imagesWithoutLogos.filter(
      item => !+item.metadata.isFeatured
    )

    const slicedImages = this.sliceArrayInGroups(
      imagesWithFeaturedFlagOn.concat(imagesWithFeaturedFlagOff),
      MAX_PHOTOS_PER_PAGE
    )

    const slicedLogos = this.sliceArrayInGroups(
      imagesWithLogoFlagOn,
      MAX_LOGOS_PER_PAGE
    )

    return (
      <>
        <Tabs
          initialTabActive="#photos"
          isDesktopOnly={true}
          id="images-tabs"
          onTabChange={this.resetCurrentPage}
        >
          <Tab link="#photos">Photos</Tab>
          <Tab link="#logos">Logos</Tab>
        </Tabs>
        <div
          id="photos"
          className={cx(
            Tabs.tabContentClass,
            Tabs.desktopOnly,
            Tabs.activeTabContent
          )}
        >
          <ul className={cx(styles.list, className)}>
            {currentFile && (
              <ImageEditor
                isCanvas={true}
                imageFile={currentFile}
                onCancel={() => {
                  this.moveToNextFile()
                }}
                onSave={(imageFile, blob, dataUrl, model) => {
                  this.moveToNextFile()
                  const messageId = ToastNotify.info('Uploading image...')

                  const transformedFile = new File([blob], imageFile.name)

                  const transformedFileAsDict = {
                    model: { ...model, communityId },
                    file: transformedFile,
                    fileAsBlob: blob,
                    fileAsDataUrl: dataUrl,
                    messageId
                  }
                  const transformedFileUUID =
                    transformedFile.name + transformedFile.size

                  this.setState(state => {
                    const selectedDataUrlsMapCopy = new Map(
                      state[STATE_SELECTED_DATA_URLS_MAP]
                    )
                    selectedDataUrlsMapCopy.set(transformedFileUUID, dataUrl)

                    const uploadsDictQueueMapCopy = new Map(
                      state[STATE_UPLOADS_DICT_QUEUE_MAP]
                    )
                    uploadsDictQueueMapCopy.set(
                      transformedFileUUID,
                      transformedFileAsDict
                    )

                    return {
                      [STATE_SELECTED_DATA_URLS_MAP]: selectedDataUrlsMapCopy,
                      [STATE_UPLOADS_DICT_QUEUE_MAP]: uploadsDictQueueMapCopy
                    }
                  })
                }}
              />
            )}

            {uploadsDictQueueMap.size > 0 && (
              <ImagesUploadsManager
                filesDictMap={uploadsDictQueueMap}
                onError={(_, file, messageId) => {
                  messageId && ToastNotify.remove(messageId)
                  ToastNotify.error(
                    <ToastMessageThumbnail
                      message="There was an error uploading the image"
                      file={file}
                    />
                  )
                }}
                onRepeated={file => {
                  this.setState(state => {
                    const uploadsDictQueueMapCopy = new Map(
                      state[STATE_UPLOADS_DICT_QUEUE_MAP]
                    )
                    uploadsDictQueueMapCopy.delete(file.name + file.size)
                    return {
                      [STATE_UPLOADS_DICT_QUEUE_MAP]: uploadsDictQueueMapCopy
                    }
                  })
                }}
                onProgress={(file, progress) => {
                  const key = file.name + file.size

                  this.setState(state => {
                    const uploadsProgressMapCopy = new Map(
                      state[STATE_UPLOADS_PROGRESS_MAP]
                    )
                    uploadsProgressMapCopy.set(key, progress)

                    const selectedDataUrlsMapCopy = new Map(
                      state[STATE_SELECTED_DATA_URLS_MAP]
                    )
                    if (progress === 100) {
                      uploadsProgressMapCopy.delete(key)
                      selectedDataUrlsMapCopy.delete(key)
                    }

                    return {
                      [STATE_SELECTED_DATA_URLS_MAP]: selectedDataUrlsMapCopy,
                      [STATE_UPLOADS_PROGRESS_MAP]: uploadsProgressMapCopy
                    }
                  })
                }}
                onSuccess={(file, response, messageId) => {
                  messageId && ToastNotify.remove(messageId)
                  const cloudinaryPayload = response.data.results

                  if (cloudinaryPayload.secureUrl) {
                    ToastNotify.success(
                      <ToastMessageThumbnail
                        message="Image successfully uploaded!"
                        file={file}
                        asset={cloudinaryPayload}
                      />,
                      { autohide: false }
                    )
                    this.setState(state => {
                      const completedFilesMapCopy = new Map(
                        state[STATE_COMPLETED_FILES_MAP]
                      )

                      completedFilesMapCopy.set(
                        file.name + file.size,
                        cloudinaryPayload
                      )

                      const completedFilesPublicIdsArrayCopy = state[
                        STATE_COMPLETED_FILES_PUBLIC_IDS_ARRAY
                      ].slice()
                      completedFilesPublicIdsArrayCopy.push(
                        cloudinaryPayload.publicId
                      )

                      const completedFilesArrayCopy = state[
                        STATE_COMPLETED_FILES_ARRAY
                      ].slice()
                      completedFilesArrayCopy.push(cloudinaryPayload)

                      onImageUpload(cloudinaryPayload)

                      return {
                        [STATE_COMPLETED_FILES_MAP]: completedFilesMapCopy,
                        [STATE_COMPLETED_FILES_PUBLIC_IDS_ARRAY]: completedFilesPublicIdsArrayCopy,
                        [STATE_COMPLETED_FILES_ARRAY]: completedFilesArrayCopy
                      }
                    })
                  }
                }}
              />
            )}

            {!readOnly && (
              <li className={styles.item}>
                <ImageSelectorButton
                  buttonAttrs={{
                    children: 'Upload Photos'
                  }}
                  onFilesSelect={files => {
                    const filteredSelectedFilesArray = Array.from(
                      files
                    ).filter(file => evalFileViability(file))
                    this.setState({
                      [STATE_SELECTED_FILES_ARRAY]: filteredSelectedFilesArray
                    })
                  }}
                  label="Max image file size 10MB."
                />
              </li>
            )}

            {isLoading && (
              <li className={styles.item}>
                <Loader.Card />
              </li>
            )}

            {Array.from(selectedDataUrlsMap.keys()).map(key => {
              const url = selectedDataUrlsMap.get(key)
              return (
                <ImagePreviewerItem
                  imageUrl={url}
                  key={key}
                  actions={
                    <ProgressControl progress={uploadsProgressMap.get(key)} />
                  }
                  type={`Array.from(selectedDataUrlsMap.keys()) ${uploadsProgressMap.get(
                    key
                  )}`}
                />
              )
            })}
            {slicedImages.length > 0
              ? slicedImages[
                  this.state.currentImagesPage
                ].map((item, index) => (
                  <ImagePreviewerItem
                    imageUrl={Cloudinary.makeThumbnailFromSecureUrl(
                      item.secureUrl
                    )}
                    key={index}
                    actions={
                      <Actions
                        readOnly={readOnly}
                        onDelete={() => onImageDelete(item)}
                        onEdit={() => onImageEdit(item)}
                        onView={() => onImageView(item)}
                      />
                    }
                    overlay={getSegregatedOverlay(
                      +item.metadata.isFeatured,
                      item.metadata.status
                    )}
                    type={
                      item.metadata.isFeatured
                        ? 'imagesWithFeaturedFlagOn'
                        : 'imagesWithFeaturedFlagOff'
                    }
                  />
                ))
              : null}
          </ul>
          {slicedImages.length > 1 && (
            <Paginator
              onClickNext={() => this.updatePage('next', 'images')}
              onClickPrev={() => this.updatePage('prev', 'images')}
              currentPage={this.state.currentImagesPage + 1}
              totalPages={slicedImages.length}
              className={cx('mat-3', styles.paginator)}
            />
          )}
        </div>
        <div id="logos" className={cx(Tabs.tabContentClass, Tabs.desktopOnly)}>
          {slicedLogos.length > 0 ? (
            <>
              <ul className={cx(styles.list, className)}>
                {slicedLogos[this.state.currentLogosPage].map((item, index) => (
                  <ImagePreviewerItem
                    imageUrl={Cloudinary.makeThumbnailFromSecureUrl(
                      item.secureUrl
                    )}
                    key={index}
                    actions={
                      <Actions
                        readOnly={readOnly}
                        onDelete={() => onImageDelete(item)}
                        onEdit={() => onImageEdit(item)}
                        onView={() => onImageView(item)}
                      />
                    }
                    overlay={getSegregatedOverlay(false, item.metadata.status)}
                    type="imagesWithLogoFlagOn"
                  />
                ))}
              </ul>
            </>
          ) : null}
          {slicedLogos.length > 1 && (
            <Paginator
              onClickNext={() => this.updatePage('next', 'logos')}
              onClickPrev={() => this.updatePage('prev', 'logos')}
              currentPage={this.state.currentLogosPage + 1}
              totalPages={slicedLogos.length}
              className={cx('mat-3', styles.paginator)}
            />
          )}
        </div>
      </>
    )
  }
}

ImagesManager.propTypes = {
  communityId: number.isRequired,
  images: array,
  className: string,
  isLoading: bool,
  readOnly: bool,
  onImageDelete: func,
  onImageEdit: func,
  onImageView: func,
  onImageUpload: func
}
ImagesManager.defaultProps = {
  images: [],
  className: '',
  isLoading: false,
  readOnly: false,
  onImageDelete: () => null,
  onImageEdit: () => null,
  onImageView: () => null,
  onImageUpload: () => null
}

export default ImagesManager
