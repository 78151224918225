import React, { Component } from 'react'
import { string, object } from 'prop-types'
import cx from 'classnames'

// components
import Layout from '@compositions/Layout'
import Loader from '@components/Loader'
import BusinessUnitsDropdown from '@compositions/BusinessUnitsDropdown'
import Paginator from '@components/Paginator'
import Table from './components/Table'
import { SORTBY_COMMUNITY_NAME } from './components/Table/constants'

// modules
import Url from '@modules/url'
import {
  STATE_DATA_REQUEST_STATUS,
  STATE_DATA_REQUEST_ERROR,
  STATE_DATA,
  STATE_ERROR,
  STATE_SEARCH,
  SORT_ASC
} from './modules/constants'
import { fetchCommunities, getSearchParamAsArray } from './modules/helpers'

// styles
import styles from './_.module.scss'

class Index extends Component {
  constructor(props) {
    super(props)

    this.state = {
      [STATE_DATA]: {
        meta: {
          totalItems: 0,
          page: 1,
          itemsPerPage: 0,
          message: ''
        },
        results: []
      },
      [STATE_DATA_REQUEST_STATUS]: true,
      [STATE_DATA_REQUEST_ERROR]: null,
      [STATE_ERROR]: false,
      [STATE_SEARCH]: Url.parseSearch(this.props.location.search)
    }
  }

  componentDidMount() {
    fetchCommunities(
      { params: Url.parseSearch(this.props.location.search) },
      this
    )
  }

  componentDidUpdate(prevProps, prevState) {
    const prevSearch = prevProps.location.search
    const search = this.props.location.search

    if (prevSearch !== search) {
      fetchCommunities({ params: Url.parseSearch(search) }, this)
    }
  }

  static getDerivedStateFromError(error) {
    return { [STATE_ERROR]: true }
  }

  render() {
    const { path, location } = this.props
    const breadcrumbs = [{ key: 'communities', text: 'Communities', url: path }]
    const {
      [STATE_DATA]: data,
      [STATE_DATA_REQUEST_ERROR]: dataRequestError,
      [STATE_DATA_REQUEST_STATUS]: dataRequestStatus,
      [STATE_ERROR]: error
    } = this.state

    if (error) {
      return <h4>Something went wrong.</h4>
    }

    const searchAsObject = Url.parseSearch(location.search)
    const selectedLeafsIds = getSearchParamAsArray(
      searchAsObject.selectedLeafsIds
    )
    const selectedBranchesIds = getSearchParamAsArray(
      searchAsObject.selectedBranchesIds
    )

    const payloadMeta = data.meta || {}

    const { roles } = payloadMeta

    return (
      <Layout
        className="baco-white po-r"
        breadcrumbs={breadcrumbs}
        flagName="communities"
      >
        <section
          className={cx('di-f-p di-n-po alit-c po-a', styles.topActions)}
        >
          <BusinessUnitsDropdown
            label="All Divisions"
            depth={2}
            onEventOutside={(selectedLeafsIds, selectedBranchesIds) => {
              Url.updateSearch(this.props.path, {
                ...searchAsObject,
                selectedLeafsIds,
                selectedBranchesIds,
                businessUnitsIds: selectedLeafsIds
              })
            }}
            selectedLeafsIds={selectedLeafsIds}
            selectedBranchesIds={selectedBranchesIds}
            contentWrapperStyle={{
              right: 0
            }}
          />
        </section>

        {dataRequestStatus ? (
          <Loader.Grid className="pat-4" template="Communities" />
        ) : dataRequestError ? (
          'We were unable to retrieve your communities at this time. Please try again shortly.'
        ) : (
          <>
            <Table
              items={data.results}
              onSort={(sortColumn, sortOrder) => {
                Url.updateSearch(this.props.path, {
                  ...searchAsObject,
                  sortColumn,
                  sortOrder
                })
              }}
              sortOrder={
                typeof searchAsObject.sortOrder === 'undefined'
                  ? SORT_ASC
                  : +searchAsObject.sortOrder
              }
              sortColumn={+searchAsObject.sortColumn || SORTBY_COMMUNITY_NAME}
              className="pat-4"
              roles={roles}
            />

            <Paginator
              onClickNext={() => {
                Url.updateSearch(this.props.path, {
                  ...searchAsObject,
                  page: (+searchAsObject.page || 1) + 1
                })
              }}
              onClickPrev={() => {
                Url.updateSearch(this.props.path, {
                  ...searchAsObject,
                  page: (+searchAsObject.page || 1) - 1
                })
              }}
              onClickStep={step => {
                Url.updateSearch(this.props.path, {
                  ...searchAsObject,
                  page: step
                })
              }}
              currentPage={payloadMeta.page}
              totalPages={Math.ceil(
                payloadMeta.totalItems / payloadMeta.itemsPerPage
              )}
              className="mat-3"
            />
          </>
        )}
      </Layout>
    )
  }
}

Index.propTypes = {
  path: string,
  location: object
}

Index.defaultProps = {
  path: '/communities',
  location: { search: '' }
}

export default Index
