import React from 'react'
import { string, oneOfType, number, array, func, bool } from 'prop-types'
import DynamicFormFields from '../DynamicFormFields'

const LifeStyle = ({ communityId, lifestyle, readOnly, setPendingChanges }) => {
  return (
    <DynamicFormFields
      key="lifeStyle"
      communityId={communityId}
      fields={lifestyle}
      readOnly={readOnly}
      setPendingChanges={setPendingChanges}
    />
  )
}

LifeStyle.propTypes = {
  communityId: oneOfType([string, number]).isRequired,
  lifestyle: array,
  readOnly: bool,
  setPendingChanges: func
}

LifeStyle.defaultProps = {
  lifestyle: [],
  readOnly: false,
  setPendingChanges: () => null
}
export default LifeStyle
