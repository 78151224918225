import React, { useState } from 'react'
import {
  arrayOf,
  shape,
  oneOfType,
  string,
  number,
  bool,
  func
} from 'prop-types'

import Radio from '@components/Radio'

const RadioList = ({ items, readOnly, onChange }) => {
  const [list, setList] = useState(items)
  return (
    <div className="di-f fldi-c">
      {list.map((item, index) => {
        return (
          <Radio
            key={index}
            id={item.tagId}
            label={item.label}
            name={`${item.name}[${item.id}]`}
            checked={item.value == true ? 1 : 0}
            onChange={evt => {
              if (readOnly) return false
              const updated = list.map((itemUpdated, indexUpdate) => {
                if (indexUpdate === index)
                  itemUpdated.value = evt.currentTarget.checked || false
                else itemUpdated.value = false
                onChange({
                  id: itemUpdated.id,
                  name: itemUpdated.name,
                  value: itemUpdated.value
                })
                return itemUpdated
              })
              setList(updated)
            }}
            disabled={readOnly}
          />
        )
      })}
    </div>
  )
}

RadioList.propTypes = {
  readOnly: bool,
  items: arrayOf(
    shape({ value: oneOfType([string, number, bool]), label: string })
  ),
  onChange: func
}

RadioList.defaultProps = {
  id: null,
  name: null,
  readOnly: false,
  items: [],
  onChange: () => null
}

export default RadioList
