import React from 'react'
import { string, oneOfType, number, array, func, bool } from 'prop-types'
import DynamicFormFields from '../DynamicFormFields'

const CareStaffing = ({
  communityId,
  careStaffing,
  setPendingChanges,
  readOnly
}) => {
  return (
    <DynamicFormFields
      key="careStaffing"
      communityId={communityId}
      fields={careStaffing}
      setPendingChanges={setPendingChanges}
      readOnly={readOnly}
    />
  )
}

CareStaffing.propTypes = {
  communityId: oneOfType([string, number]).isRequired,
  careStaffing: array,
  readOnly: bool,
  setPendingChanges: func
}

CareStaffing.defaultProps = {
  careStaffing: [],
  readOnly: false,
  setPendingChanges: () => null
}
export default CareStaffing
