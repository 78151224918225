import React, { Component } from 'react'
import { any, func, string } from 'prop-types'

// modules
import Cloudinary from '@modules/cloudinary'
import CoreImage from '@modules/core-image'

// CoreImages
const ASSET_TYPE_IMAGE = 1
const ASSET_ENTITY_TYPE = 1 // Always type community

// accessors
const STATE_UPLOADS_IN_PROGRESS_SET = 'uploadsInProgressSet'

// helpers

const calcProgressTotal = (event, step, totalSteps) => {
  const totalPercentage = (step / totalSteps) * 100
  return Math.round((event.loaded * totalPercentage) / event.total)
}

const manageHelpers = (ctx, paramObj) => {
  let dict, file, model, key
  const onProgress = (file, step = 1, totalSteps = 1) => progress =>
    paramObj.onProgress(file, calcProgressTotal(progress, step, totalSteps))
  const onSuccess = file => response => paramObj.onSuccess(file, response, paramObj.messageId)
  const onError = (error, file) => paramObj.onError(error, file, paramObj.messageId)

  const xhrWrapper = (formData, file) => {
    const modelCoreImg = {
      communityId: model.communityId,
      assetType: ASSET_TYPE_IMAGE,
      entityType: ASSET_ENTITY_TYPE,
      isLogo: Boolean(model.isLogo || false),
      isDefault: Boolean(model.isFeatured || false)
    }
    let step = 1
    const TOTAL_STEPS = 2
    CoreImage.uploadSigned(
      formData,
      {
        onUploadProgress: onProgress(file, step++, TOTAL_STEPS),
        params: modelCoreImg
      },
      (err, res) => {
        if (err) {
          onError({ errors: [err] }, file)
          return console.error(err)
        }

        const { data } = res || {}
        const { assets, propertyId } = (data && data.results) || {}

        let assetId
        if(assets && assets.length){
          assetId = assets[0].assetId
        }

        model.metadata = [`assetId|${assetId}`]

        Cloudinary.uploadSigned(
          formData,
          {
            onUploadProgress: onProgress(file, step++, TOTAL_STEPS),
            params: model
          },
          (err, res) => {
            if (err) {
              // Remove it from Core Images
              const config = {
                params: {
                  imageId: assetId,
                  entityId: propertyId
                }
              }
              CoreImage.deleteSigned(config, errDeleting =>
                onError({ errors: [err, errDeleting] }, file)
              )
              return null
            }

            return err ? null : onSuccess(file)(res)
          }
        )
      }
    )
  }

  ctx.setState(state => { 
    const uploadsInProgressSetCopy = new Set(state[STATE_UPLOADS_IN_PROGRESS_SET])

    for (let filesDictItem of paramObj.filesDictMap) {
      dict = filesDictItem[1]
      file = dict.file
      model = dict.model
      key = file.name + file.size

      if (!uploadsInProgressSetCopy.has(key)) {
        let formData = new FormData()
        formData.append('assetFile', file)
        uploadsInProgressSetCopy.add(key, xhrWrapper(formData, file))
      } else {
        paramObj.onRepeated(file)
      }
    }

    return {
      [STATE_UPLOADS_IN_PROGRESS_SET]: uploadsInProgressSetCopy
    }
  })
}

class ImagesUploadsManager extends Component {
  state = {
    [STATE_UPLOADS_IN_PROGRESS_SET]: new Set()
  }

  componentDidMount() {
    const { filesDictMap, onProgress, onSuccess, onError, onRepeated, messageId } = this.props
    manageHelpers(this, { filesDictMap, onProgress, onSuccess, onError, onRepeated, messageId })
  }

  componentDidUpdate() {
    const { filesDictMap, onProgress, onSuccess, onError, onRepeated, messageId } = this.props
    manageHelpers(this, { filesDictMap, onProgress, onSuccess, onError, onRepeated, messageId })
  }

  render() {
    return <></>
  }
}

ImagesUploadsManager.propTypes = {
  filesDictMap: any.isRequired,
  messageId: string,
  onProgress: func,
  onSuccess: func,
  onError: func,
  onRepeated: func
}

ImagesUploadsManager.defaultProps = {
  messageId: null,
  onProgress: () => null,
  onSuccess: () => null,
  onError: () => null,
  onRepeated: () => null
}

export default ImagesUploadsManager
