import { FEE_TYPES } from '../constants'

export const getFeeTypes = (careTypes, feeTypes = new Map()) => {
  FEE_TYPES.forEach(item => feeTypes.set(item.id, item))
  if (careTypes && careTypes.length > 0) {
    careTypes.forEach(careType => {
      const { fees } = careType || {}
      if (fees && fees.length > 0) {
        fees.forEach(fee => {
          if (feeTypes.has(fee.id)) {
            feeTypes.delete(fee.id)
          }
        })
      }
    })
  }
}

export const orderByChildren = items => {
  const parents = new Map()
  for (let item of items) {
    const { fees, ...careType } = item
    for (let fee of fees) {
      if (parents.has(fee.id)) {
        const parent = parents.get(fee.id)
        parent.children.set(item.code, { ...careType, amount: fee.amount })
        parents.set(fee.id, parent)
      } else {
        const children = new Map()
        children.set(item.code, { ...careType, amount: fee.amount })
        const { amount, ...rest } = fee
        parents.set(fee.id, { ...rest, children })
      }
    }
  }
  return parents
}

export const feesToArray = (feesMap, careTypesDataset) => {
  const fees = []
  const allowedCareTypes = new Map(careTypesDataset)
  for (let fee of feesMap.values()) {
    const { children: careTypesMap, ...otherFeeAttrs } = fee
    const careTypes = []
    const pendingCareTypes = new Map(careTypesDataset)
    for (let careType of careTypesMap.values()) {
      if (pendingCareTypes.has(careType.code)) {
        pendingCareTypes.delete(careType.code)
      }
      allowedCareTypes.set(careType.code, { ...careType, isFake: true })
      careTypes.push(careType)
    }
    if (pendingCareTypes.size > 0) {
      for (let pendingCareTypeId of pendingCareTypes.keys()) {
        let careTypeKey = allowedCareTypes.get(pendingCareTypeId)
        if (typeof careTypeKey === 'string') {
          careTypeKey = {
            code: pendingCareTypeId,
            description: careTypeKey,
            isFake: true
          }
        }
        careTypes.push(careTypeKey)
      }
    }
    fees.push({ ...otherFeeAttrs, careTypes })
  }
  return fees
}

export const roomChargesFill = roomChargesPayload => {
  const roomCharges = []
  const allowedCareTypes = new Map()
  const roomChargesMap = new Map()
  if (!roomChargesPayload) return roomCharges
  roomChargesPayload.forEach(item => {
    const roomTypes = new Map()
    item.roomTypes.forEach(subItem => {
      allowedCareTypes.set(subItem.id, { ...subItem, isFake: true })
      roomTypes.set(subItem.id, subItem)
    })
    roomChargesMap.set(item.code, { ...item, roomTypes })
  })
  for (let careType of roomChargesMap.values()) {
    const { roomTypes: roomTypesMap } = careType
    const roomTypes = []
    for (let room of allowedCareTypes.values()) {
      if (!roomTypesMap.has(room.id)) {
        roomTypesMap.set(room.id, room)
      }
      roomTypes.push(roomTypesMap.get(room.id))
    }
    roomCharges.push({ ...careType, roomTypes })
  }

  return roomCharges
}
