import React, { useState, useEffect } from 'react'
import { number, string, bool } from 'prop-types'

// modules
import { Collection } from '@modules/rest-api'
import { REST_API_ENDPOINTS } from '@modules/constants'
import {
  handleUpdate,
  handleCreate,
  handleRemoveEmployee,
  handleEditFormErrors
} from '@components/EditEmployeeForm/modules/helpers'

// components
import PersonnelList from './components/PersonnelList'
import EditEmployeeForm from '@components/EditEmployeeForm'
import RemoveEmployee from './components/RemoveEmployee'

const PersonnelOptions = ({ readOnly, communityId, communityName, communityAddress }) => {
  const [editEmployee, setEditEmployee] = useState(null)
  const [removeEmployee, setRemoveEmployee] = useState(null)
  const [addEmployee, setAddEmployee] = useState(false)
  const [errors, setErrors] = useState({})
  const [updatedEmployee, setUpdatedEmployee] = useState(null)
  const [roles, setRoles] = useState({ isLoading: true, data: [], error: null })

  useEffect(() => {
    const rolesCollection = new Collection(REST_API_ENDPOINTS.roles)
    rolesCollection.readSigned({}, (error, res) => {
      if (error) {
        return setRoles(prevState => ({ ...prevState, error }))
      }
      const { data: response } = res || {}
      const data = (response && response.results) || []
      setRoles(prevState => ({
        ...prevState,
        isLoading: false,
        data
      }))
    })
  }, [])

  return (
    <>
      {readOnly ? null : editEmployee ? (
        <EditEmployeeForm
          employee={editEmployee}
          rolesDataSource={roles}
          closeModal={() => setEditEmployee(null)}
          onFormSubmit={({ isPrimary, model, callback }) =>
            handleUpdate(isPrimary, model, editEmployee, (err, res) => {
              let networkIssues = false
              handleEditFormErrors(err, setErrors, networkIssues)
              const { data, status } = res || { status: 500, data: [] }
              const success = !err && status === 200
              if (success) {
                const resEmployee = (data && data.results) || {}
                setUpdatedEmployee({ ...editEmployee, ...resEmployee })
              }
              callback(success, networkIssues)
            })
          }
        />
      ) : addEmployee ? (
        <EditEmployeeForm
          isNew={true}
          rolesDataSource={roles}
          closeModal={() => setAddEmployee(false)}
          errors={errors}
          onFormSubmit={({ model, callback }) =>
            handleCreate(
              REST_API_ENDPOINTS.personnel,
              { ...model, communityId, communityName, communityAddress },
              roles,
              (err, res) => {
                let networkIssues = false
                handleEditFormErrors(err, setErrors, networkIssues)
                const status = (res && res.status) || 500
                callback(!err && status === 200, networkIssues)
              }
            )
          }
        />
      ) : (
        removeEmployee && (
          <RemoveEmployee
            employee={removeEmployee}
            closeModal={() => setRemoveEmployee(false)}
            onConfirm={({ employee, callback }) =>
              handleRemoveEmployee(employee, (err, res) => {
                if (err) {
                  console.warn(err)
                }
                const status = (res && res.status) || 500
                const success = !err && status === 200
                if (success) {
                  setUpdatedEmployee({ ...employee, endDate: Date.now() })
                }
                callback(success)
              })
            }
          />
        )
      )}
      <PersonnelList
        readOnly={readOnly}
        communityId={communityId}
        communityName={communityName}
        rolesDataSource={roles}
        updatedEmployee={updatedEmployee}
        onEmployeeClick={employee => setEditEmployee(employee)}
        onRemoveEmployeeClick={employee => setRemoveEmployee(employee)}
        onAddEmployeeClick={() => setAddEmployee(true)}
      />
    </>
  )
}

PersonnelOptions.propTypes = {
  readOnly: bool,
  communityId: number,
  communityName: string,
  communityAddress: string
}

PersonnelOptions.defaultProps = {
  readOnly: false,
  communityId: null,
  communityName: null,
  communityAddress: null
}

export default PersonnelOptions
