import React from 'react'
import cx from 'classnames'
import { string, node } from 'prop-types'

// components
import Header from './components/Header'
import Box from './components/Box'
import Fields from './components/Fields'
import Field from './components/Field'

// styles
import styles from './_.module.scss'

const GroupFields = ({ children, footer, className }) => (
  <div className={cx('wi-100', 'fosi-2', className)}>
    <div
      className={cx('di-f', 'fldi-c', 'pax-4', 'may-2', 'wi-100', styles.table)}
    >
      {children}
      <div className="pay-2">{footer}</div>
    </div>
  </div>
)

GroupFields.propTypes = {
  children: node,
  footer: node,
  className: string
}

GroupFields.defaultProps = {
  children: null,
  footer: null,
  className: null
}

GroupFields.Header = Header
GroupFields.Row = Box
GroupFields.Fields = Fields
GroupFields.Field = Field

export { Header, Box, Fields, Field }
export default GroupFields
