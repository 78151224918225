import React, { useRef } from 'react'
import { string, object, func, bool, any } from 'prop-types'

import cx from 'classnames'

// components
import Button from '@components/Button'

// assets
import uploadIconUrl from './assets/icon.svg'

// styles
import styles from './_.module.scss'

const ImageSelectorButton = ({
  buttonAttrs,
  onFilesSelect,
  label,
  isMultiple,
  readOnly,
  assetToMigrate
}) => {
  const inputEl = useRef()
  const backgroundImage = assetToMigrate
    ? `url(${assetToMigrate.imageUrl})`
    : `url(${uploadIconUrl})`

  return (
    <div
      className={cx(
        'po-r',
        'di-f',
        'juco-c',
        'alit-c',
        'bora-1',
        'fldi-c',
        styles.imageUploader,
        assetToMigrate
          ? styles.imageUploaderExtended
          : styles.imageUploaderStandard
      )}
      style={{
        backgroundImage
      }}
    >
      <input
        type="file"
        multiple={isMultiple}
        accept="image/jpeg, image/jpg, image/png"
        className="di-n"
        onChange={event => {
          onFilesSelect(isMultiple ? event.target.files : event.target.files[0])
          event.target.value = ''
        }}
        ref={inputEl}
      />

      {!readOnly && (
        <Button
          {...buttonAttrs}
          palette="white"
          className={cx(
            'pa-2',
            assetToMigrate ? styles.buttonExtended : styles.button,
            buttonAttrs.className
          )}
          onClick={() => inputEl.current.click()}
          type="button"
        />
      )}

      {!readOnly && (
        <span className={cx('fosi-1 teal-c', styles.label)}>{label}</span>
      )}
    </div>
  )
}

ImageSelectorButton.propTypes = {
  assetToMigrate: any,
  buttonAttrs: object,
  label: string,
  isMultiple: bool,
  readOnly: bool,
  handleUpload: func,
  onFilesSelect: func
}

ImageSelectorButton.defaultProps = {
  buttonAttrs: {},
  isMultiple: true,
  readOnly: false,
  label: 'Max image file size 10MB.',
  handleUpload: () => null,
  onFilesSelect: () => null,
  assetToMigrate: null
}

export default ImageSelectorButton
