import React, { Component } from 'react'
import { string, number, func, node, object, bool } from 'prop-types'

// components
import TextField from '@components/TextField'
import Icon from '@components/Icon'

// modules
import Formatter from '@modules/formatter'

const STATE_VALUE = 'inputValue'

const onBlur = (event, setValue, cb, config = {}) => {
  const { value } = event.currentTarget
  if (parseFloat(value) === 0) {
    setValue('')
    cb({ [STATE_VALUE]: '' })
  } else {
    setValue(Formatter.money(value, { fillZero: true, ...config }))
    cb()
  }
}

class NumericTextField extends Component {
  constructor(props) {
    super(props)
    const { defaultValue: uncontrolledValue, value: controlledValue } = props
    const value = uncontrolledValue || controlledValue
    this.state = {
      [STATE_VALUE]: (value && Formatter.money(value, props.config)) || ''
    }
  }
  render() {
    const {
      name,
      className,
      onChange,
      onFocus,
      icon,
      maxLength,
      config,
      readOnly,
      ...rest
    } = this.props
    const value = this.state[STATE_VALUE]
    return (
      <TextField.WithIcon
        {...rest}
        readOnly={readOnly}
        inputClassName={className}
        type="text"
        palette="white"
        placeholder="N/A"
        name={name}
        value={value}
        icon={icon}
        autoComplete="off"
        maxLength={maxLength}
        applyMask={Formatter.filterNumericText}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={(event, setValue) =>
          !readOnly && onBlur(
            event,
            setValue,
            state => state && this.setState(state),
            config
          )
        }
      />
    )
  }
}

NumericTextField.propTypes = {
  className: string,
  icon: node,
  maxLength: number,
  name: string,
  value: number,
  defaultValue: number,
  config: object,
  readOnly: bool,
  onChange: func,
  onFocus: func
}

NumericTextField.defaultProps = {
  name: '',
  className: null,
  value: 0,
  defaultValue: 0,
  icon: <Icon name="price" size={20} />,
  onChange: () => null,
  onFocus: () => null,
  maxLength: 12,
  config: {},
  readOnly: false
}

export default NumericTextField
