import React, { useEffect, useState } from 'react'
import { number, any, func, bool } from 'prop-types'
import cx from 'classnames'

// components
import { ToastNotify } from '@compositions/Toast'
import ImagesManager from '@components/ImagesManager'
import Chip from '@components/Chip'
import Section from '@components/Section'
import ImageEditor from '@components/ImageEditor'

// modules
import { REST_API_ENDPOINTS } from '@modules/constants'
import { Collection } from '@modules/rest-api'
import { IMAGE_STATUSES } from '@modules/constants'
import Cloudinary from '@modules/cloudinary'
import CoreImage from '@modules/core-image'

// styles
import styles from './_.module.scss'

let deletingMsgId = null
// helpers
function getCuratedImagesAfterDelete(images, deletedImage) {
  let curatedImagesAfterDelete = images.slice()
  for (let [index, image] of images.entries()) {
    if (image.publicId === deletedImage.publicId) {
      curatedImagesAfterDelete.splice(index, 1)
    }
  }

  return curatedImagesAfterDelete
}

function handleBadRequestErrors(err, cb) {
  if (err) {
    deletingMsgId && ToastNotify.remove(deletingMsgId)
    ToastNotify.error('There was an error deleting the image')
    const response = err.response || {}
    const errorResponseStatus = response.status
    if (errorResponseStatus >= 400 && errorResponseStatus < 500) {
      return cb()
    } else {
      return cb(err)
    }
  }
}

function deleteFromCloudinary(image, cb) {
  Cloudinary.deleteSigned(image.publicId, {}, (err, res) => {
    if (err) return handleBadRequestErrors(err, cb)
    else cb()
  })
}

function deleteSegregatedImage(image, cb) {
  deletingMsgId = ToastNotify.info('Deleting image...')
  CoreImage.deleteSigned(
    {
      params: {
        imageId: image.metadata.assetId,
        entityId: image.metadata.communityId
      }
    },
    cb
  )
}

function getImagesByStatus(images, status) {
  const imagesByStatus = images.filter(image => {
    const context = image.metadata || {}
    return context.status == status
  })

  return imagesByStatus
}

const Photos = ({ communityId, newLogo, readOnly, onLogoAddedSuccess }) => {
  const [results, setResults] = useState([])
  const [hasRejected, setHasRejected] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [imageToEdit, setImageToEdit] = useState(null)
  const [imageToView, setImageToView] = useState(null)

  useEffect(() => {
    const imageStatusesSum =
      IMAGE_STATUSES.pending + IMAGE_STATUSES.approved + IMAGE_STATUSES.rejected

    const collection = new Collection(REST_API_ENDPOINTS.communityAssets, 1)
    collection.readSigned(
      {
        params: {
          communityIds: [communityId],
          moderationStatusFlags: imageStatusesSum
        }
      },
      (err, res) => {
        if (err) return // TODO: manage error
        setIsLoading(false)
        const images = res.data.results
        setResults(images)
        const rejectedImages = getImagesByStatus(
          images,
          IMAGE_STATUSES.rejected
        )
        setHasRejected(rejectedImages.length)
      }
    )
  }, [communityId])

  useEffect(() => {
    if (newLogo) {
      setResults([newLogo, ...results])
      onLogoAddedSuccess()
    }
  }, [results, newLogo, onLogoAddedSuccess])

  return (
    <section id="photos-section">
      <Section
        title="Images"
        description={`
          We recommend placing a picture of the exterior first, but add pictures of rooms, lobbies, food, and amenities too. The first image is featured and shows up first.
        `}
      >
        {hasRejected ? (
          <Chip
            theme="star"
            isActive={true}
            className="co-orange-4 pa-2 mat-4"
            canChangeState={false}
          >
            Some of the uploaded images did not pass our moderation process.
          </Chip>
        ) : null}
        <ImagesManager
          images={results}
          communityId={communityId}
          className={cx(styles.manager)}
          isLoading={isLoading}
          onImageUpload={uploadedImage => {
            const resultsCopy = results.slice()

            let isImageInResults
            for (let image of resultsCopy) {
              isImageInResults = image.publicId === uploadedImage.publicId
            }

            if (!isImageInResults) resultsCopy.push(uploadedImage)

            setResults(resultsCopy)
          }}
          onImageDelete={image =>
            deleteSegregatedImage(image, () => {
              deleteFromCloudinary(image, err => {
                if (!err) {
                  deletingMsgId && ToastNotify.remove(deletingMsgId)
                  ToastNotify.success('Image successfully deleted')
                }
                setResults(getCuratedImagesAfterDelete(results, image))
              })
            })
          }
          onImageEdit={image => setImageToEdit(image)}
          onImageView={image => setImageToView(image)}
          readOnly={readOnly}
        />
      </Section>

      <Section title="Legal">
        <p className="fosi-2 pab-4">
          By submitting content and/or photos, you represent that the content
          and/or photos are accurate and not false or intentionally misleading;
          you own or have the necessary permissions to use and authorize the use
          of the content and/or photos; and your submission of the content
          and/or photos does not violate any third-party right (including any
          intellectual property or proprietary rights). You agree that A Place
          for Mom is entitled to the unrestricted use, dissemination,
          distribution, display, reproduction, sublicense, post, and/or
          publication of your content and/or photos for any purpose, commercial
          or otherwise.
        </p>
        <p className="fosi-2 pab-4">
          You agree that your submitted content and/or photos may also be used
          by third parties with whom we have contracted to provide services to
          us and/or you, including third parties who may be publishing your
          content and/or photos on their websites.
        </p>
        <p className="fosi-2 pab-4">
          If you have any questions in regards to updating your APFM Community
          Page please contact webpageteam@aplaceformom.com for assistance.
          Please allow up to 5 business days for your Community Page to go live
          on our A Place For Mom website.
        </p>
      </Section>

      {imageToEdit && (
        <ImageEditor
          imageToEdit={imageToEdit}
          onCancel={() => setImageToEdit(null)}
        />
      )}
      {imageToView && (
        <ImageEditor
          imageToEdit={imageToView}
          onCancel={() => setImageToView(null)}
          readOnly
        />
      )}
    </section>
  )
}

Photos.propTypes = {
  communityId: number.isRequired,
  newLogo: any,
  readOnly: bool,
  onLogoAddedSuccess: func
}

Photos.defaultProps = {
  newLogo: null,
  readOnly: false,
  onLogoAddedSuccess: () => null
}

export default Photos
