import React from 'react'
import { func, object, bool } from 'prop-types'
import { ToastNotify } from '@compositions/Toast'
import EditControls from '@components/EditControls'
import { THEMES } from '@components/constants'

const RemoveFooter = ({removing, exit, setRemoving, onConfirm, employee}) => {
return !removing && (
        <EditControls
            disabled={removing}
            onCancel={exit}
            okText="REMOVE"
            okPalette={THEMES.orange}
            onSave={() => {
                setRemoving(true)
                onConfirm({
                employee,
                callback: success => {
                    setRemoving(false)
                    if (success) {
                    exit()
                    ToastNotify.success('Employee removed successfully')
                    } else {
                    ToastNotify.error(
                        'There was an error attempting to remove the account. Please try again later or email partners@aplaceformom.com.'
                    )
                    }
                }
                })
            }}
        />
    )
}

RemoveFooter.propTypes = {
    removing: bool,
    exit: func,
    employee: object,
    setRemoving: func,
    onConfirm: func
    
}
RemoveFooter.defaultProps = {
    removing: false,
    exit: () => null,
    employee: {},
    setRemoving: () => null,
    onConfirm: () => null
}

export default RemoveFooter