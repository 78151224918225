export const STATE_EVENTS_REQUEST_STATUS = 'eventsRequestStatus'
export const STATE_EVENTS_REQUEST_ERROR = 'eventsRequestError'
export const STATE_EVENTS_REQUEST_SUCCEED = 'eventsRequestResponse'
export const STATE_REMOVING_EVENT_ID = 'removingEventId'
export const STATE_IS_EVENT_ACTION_MODAL = 'isEventActionModal'
export const STATE_IS_EVENT_FORM_ENABLED = 'isEventFormEnabled'
export const STATE_EVENT_CREATE_ERROR = 'eventCreateError'
export const STATE_EVENT_ACTION_STATUS = 'eventActionStatus'
export const STATE_EVENT_VALIDATION_ERRORS = 'eventValidationErrors'
export const ITEMS_PER_PAGE = 10
export const STATE_EVENT_MODEL = 'eventModel'
export const STATE_ACTION_VERB = 'actionVerb'
