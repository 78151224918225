import React from 'react'
import { func } from 'prop-types'
import EditControls from '@components/EditControls'
import { THEMES } from '@components/constants'

const WarningFooter = ({exit}) => {
return (
        <EditControls
            onCancel={exit}
            cancelText="OK"
            cancelPalette={THEMES.orange}
            hideOk={true}
        />
    )
}

WarningFooter.propTypes = {
    exit: func
}
WarningFooter.defaultProps = {
    exit: () => null
}

export default WarningFooter;