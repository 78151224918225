import React from 'react'
import { func, string, bool } from 'prop-types'

import Icon from '@components/Icon'

const StatusMessage = ({ label, message, showViewChanges, onClick }) => {
  return (
    <div className="di-f alit-c">
      <Icon className="co-gray-3" name="pending" size={20} />
      <span className="fosi-2 co-gray-3 pax-1">{message}</span>
      {showViewChanges && <a
        href="#/"
        className="fosi-2"
        onClick={event => {
          event.preventDefault()
          onClick(event)
        }}
      >
        {label}
      </a>}
    </div>
  )
}

StatusMessage.propTypes = {
  label: string,
  message: string,
  showViewChanges: bool,
  onClick: func
}
StatusMessage.defaultProps = {
  label: 'View Changes',
  message: 'Changes pending approval.',
  showViewChanges: true,
  onClick: () => null
}

export default StatusMessage
