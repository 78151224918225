import React from 'react'
import { bool, string, func } from 'prop-types'

// components
import ConditionalFormattedTextArea from '@components/ConditionalFormattedTextArea'
import Section from '@components/Section'
import StatusMessage from '@components/StatusMessage'
import Button from '@components/Button'
import { THEMES } from '@components/constants'

// styles
import styles from './_.module.scss'

const Description = ({
  hasPending,
  descriptionText,
  isValidForm,
  isUpdating,
  isValidText,
  readOnly,
  disabled,
  onChange
}) => (
  <Section
    id="description-section"
    title={
      <div className="di-f alit-c juco-sb">
        Description
        {hasPending && <StatusMessage showViewChanges={false} />}
      </div>
    }
    description={`
        Writing a unique description will help you show up higher in Google.
        Please be thorough, accurate, and friendly.
      `}
  >
    <div className="di-f flwr-w alit-fs">
      <section className="flba-50 flba-100-tpo mab-4">
        <ConditionalFormattedTextArea
          defaultValue={descriptionText}
          name="description"
          min={50}
          minError="Your description should be a minimum of 50 characters."
          max={4000}
          maxError="Please limit your description to 4,000 characters or fewer."
          disabled={disabled}
          readOnly={readOnly}
          supportHTML={true}
        />
        <div className="di-f">
          {!disabled && (
            <Button
              type="submit"
              className="mat-4 pax-3 pay-2"
              theme={THEMES.primary}
              palette={THEMES.primary}
              disabled={!isValidForm || isUpdating || !isValidText || readOnly}
            >
              {isUpdating ? 'Saving...' : 'Save Changes'}
            </Button>
          )}
        </div>
      </section>
      <section className="fosi-2 pax-3 flba-50 flba-100-tpo">
        <p className="pab-4">
          If you would like to update your description on Senior Living Finder
          or My Search, enter your new text in the box provided. The property
          description should give an overview of your community that will
          educate families and potential residents about the care and services
          you provide, and encourage them to schedule a tour. The following
          questions provide suggestions for many of the things you may want to
          include in your community description. These questions are tools to
          get you started. (Note: community addresses, specific street location,
          phone numbers, and web/email addresses cannot be included in the text
          of your Community Page.)
        </p>
        <p className="pab-4">Building Credibility</p>
        <ol className={`pab-4 ${styles.orderList}`}>
          <li>How long have you been in business? When did you open?</li>
          <li>
            How much experience do you have in senior care?(example: “Our staff
            members have over 30 years combined experience in senior care.”)
          </li>
          <li>
            Have you won any awards? Are you certified by any reputable
            organizations (BBB)?
          </li>
          <li>
            Are you part of a national chain? What is unique and special about
            your brand?
          </li>
        </ol>
        <p className="pab-4">Geographic Information</p>
        <ol className={`pab-4 ${styles.orderList}`}>
          <li>
            What is the name of the neighborhood/region in which your community
            is located?
          </li>
          <li>What would attract someone to this neighborhood?</li>
          <li> What are some of the things close to your community?</li>
          <li>What nearby cities and neighborhoods do you serve?</li>
        </ol>
        <p className="pab-4">Community Information</p>
        <ol className={`pab-4 ${styles.orderList}`}>
          <li>Describe the design of your campus. What makes it unique?</li>
          <li>What are some key features of the property?</li>
        </ol>
      </section>
    </div>
  </Section>
)

Description.propTypes = {
  hasPending: bool,
  descriptionText: string,
  isValidForm: bool,
  isUpdating: bool,
  isValidText: bool,
  readOnly: bool,
  disabled: bool,
  onChange: func
}
Description.defaultProps = {
  hasPending: false,
  descriptionText: null,
  isFetching: false,
  isValidForm: false,
  isUpdating: false,
  isValidText: false,
  readOnly: false,
  disabled: false,
  onChange: () => null
}

export default Description
