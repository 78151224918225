import React from 'react';
import { object } from 'prop-types';

const WarningBody= ({employee}) => (
    <div className="di-f fldi-c max-2">
        <h4 className="fowe-b mab-1">Confirm remove employee?</h4>
        <span className="fosi-1 fowe-b">
            Employee: {employee.firstName} {employee.lastName}
        </span>
    </div>
)

WarningBody.propTypes = {
    employee: object
    
}
WarningBody.defaultProps = {
    employee: {
        firsname: '',
        lastName: ''
    }
}


export default WarningBody;