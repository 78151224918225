import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import cx from 'classnames'
import { string, func, object, bool } from 'prop-types'

// modules
import * as actions from '@modules/redux/actions'
import { REST_API_ENDPOINTS } from '@modules/constants'
import Router from '@modules/router'
import Env from '@modules/env'
import { Resource } from '@modules/rest-api'

// components
import Button from '@components/Button'
import SupportEditMode from '@components/SupportEditMode'
import FeatureToggle from '@components/FeatureToggle'
import ScoreOverview from './components/ScoreOverview'
import SpecsSidebar from './components/SpecsSidebar'
import BeforeUnload from './components/BeforeUnload'

import {
  STATE_COMMUNITY,
  STATE_COMMUNITY_REQUEST_STATUS,
  STATE_COMMUNITY_REQUEST_ERROR,
  STATE_COMMUNITY_HAS_PENDING_CHANGES,
  STATE_SHOW_CONFIRM_PENDING_CHANGES,
  STATE_NEW_LOGO
} from '@compositions/Communities/modules/constants'

import {
  handleFetchResource,
  setPendingChanges,
  showConfirmationModal,
  hideConfirmationModal
} from '@compositions/Communities/modules/helpers'

// components
import Layout from '@compositions/Layout'
import MediaObject from '@components/MediaObject'
import { Tabs, Tab } from '@components/Tabs'
import ConfirmationModal from '@components/ConfirmationModal'

import Overview from './components/Overview'
import Pricing from './components/Pricing'
import Events from './components/Events'
import CareStaffing from './components/CareStaffing'
import Accommodations from './components/Accommodations'
import LifeStyle from './components/LifeStyle'
import PersonnelOptions from './components/PersonnelOptions'

// styles
import styles from './_.module.scss'

let onSaveAndLeaveHandler = () => null
let onDontSaveHandler = () => null

const appendItemsName = list => {
  const mapItems = (level = 0, parentName = '') => item => {
    let name = `${item.title || item.label}`.split(' ').join('')
    name = `${name.substr(0, 1).toLowerCase()}${name.substr(1)}`
    if (level === 0) {
      parentName = `${name}`
    }
    name = parentName
    const newItem = {
      ...item,
      tagId: `${name}-${item.id}`,
      value: item.value || '',
      name
    }
    const items = item.children || item.items
    if (items) {
      newItem.items = items.map(mapItems(level + 1, parentName))
    }

    return newItem
  }
  return list.map(mapItems())
}

let tabsRef = null
const DESCRIPTION_SCORE = 'Description'
const PRICING_SCORE = 'Pricing'
const PHOTOS_SCORE = 'Photos'
const handleCompleteProfileGuide = step => {
  if (tabsRef) {
    if (step === PRICING_SCORE) {
      tabsRef.goToTab('#pricing')
    } else {
      tabsRef.goToTab('#overview')
    }
  }
  Env.isClient(() => {
    let elem = {}
    switch (step) {
      case DESCRIPTION_SCORE:
        elem = Env.getDocument().getElementById('description-section')
        break
      case PRICING_SCORE:
        elem = Env.getDocument().getElementById('pricing-section')
        break
      case PHOTOS_SCORE:
      default:
        elem = Env.getDocument().getElementById('photos-section')
        break
    }
    Env.getWindow().scrollTo(0, elem.offsetTop)
    elem.classList.add('bg-focus')
    setTimeout(() => elem.classList.remove('bg-focus'), 2000)
  })
}
class Community extends Component {
  state = {
    [STATE_COMMUNITY]: null,
    [STATE_COMMUNITY_REQUEST_STATUS]: true,
    [STATE_COMMUNITY_REQUEST_ERROR]: null,

    [STATE_COMMUNITY_HAS_PENDING_CHANGES]: false,
    [STATE_SHOW_CONFIRM_PENDING_CHANGES]: false,
    [STATE_NEW_LOGO]: null
  }

  componentDidMount() {
    const { id, setSyntheticBreadcrumbItems } = this.props

    if (id && isNaN(id)) return Router.go('/404')

    const resource = new Resource(REST_API_ENDPOINTS.communities)
    resource.readSigned(
      id,
      {},
      handleFetchResource(setSyntheticBreadcrumbItems, this)
    )
  }
  componentWillUnmount() {
    const { setSyntheticBreadcrumbItems } = this.props
    setSyntheticBreadcrumbItems([])
  }
  render() {
    const { path, location, hasEditAccess } = this.props
    const {
      [STATE_COMMUNITY]: communityResponse,
      [STATE_COMMUNITY_REQUEST_STATUS]: isFetching,
      [STATE_COMMUNITY_REQUEST_ERROR]: communityError,

      [STATE_COMMUNITY_HAS_PENDING_CHANGES]: hasPendingChanges,
      [STATE_SHOW_CONFIRM_PENDING_CHANGES]: showConfirmPendingChanges,
      [STATE_NEW_LOGO]: newLogoAdded
    } = this.state
    const { results: community } = communityResponse || {}

    const breadcrumbs = [
      { key: 'communities', text: 'Community', url: path.replace(':id', '') }
    ]

    communityError && console.warn(communityError)
    let careStaffing = []
    let accommodations = []
    let lifestyle = []
    if (community) {
      careStaffing = appendItemsName(community.careStaffing || [])
      accommodations = appendItemsName(community.accommodations || [])
      lifestyle = appendItemsName(community.lifestyle || [])
    }
    return (
      <Layout
        className="baco-white po-r"
        breadcrumbs={breadcrumbs}
        flagName="communities"
      >
        {communityError ? (
          'An error occurred while loading data...'
        ) : !communityResponse && isFetching ? (
          'Loading...'
        ) : (
          <BeforeUnload
            onBeforeUnload={event =>
              hasPendingChanges && event.preventDefault()
            }
          >
            <div
              className={cx('di-f-p di-n-po alit-c po-a', styles.topActions)}
            >
              <a
                href={`https://www.aplaceformom.com/community/${community.id}`}
                className={styles.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button palette="white" className="pay-2 pax-3">
                  View Live
                </Button>
              </a>
            </div>
            <MediaObject
              className={styles.mediaObject}
              alignCenter={false}
              float={MediaObject.FLOAT_LEFT}
            >
              <MediaObject.Sidebar
                className={styles.sidebar}
                style={{ width: 'auto', minWidth: 360 }}
              >
                <SpecsSidebar
                  community={community}
                  onNewLogoAdded={cloudinaryPayload => {
                    this.setState({ [STATE_NEW_LOGO]: cloudinaryPayload })
                  }}
                  readOnly={!hasEditAccess}
                />
                <PersonnelOptions
                  readOnly={!hasEditAccess}
                  communityId={community.id}
                  communityName={community.name}
                  communityAddress={community.address}
                />
              </MediaObject.Sidebar>

              <MediaObject.Content className={cx('mal-2', styles.content)}>
                {showConfirmPendingChanges && (
                  <ConfirmationModal
                    headerText="Are you sure?"
                    message="You have unsaved changes. Did you mean to leave without saving?"
                    cancelText="Don't Save"
                    okText="Save and leave"
                    onCancel={onDontSaveHandler}
                    onOk={onSaveAndLeaveHandler}
                    onClose={() => {
                      onDontSaveHandler()
                      setTimeout(() => hideConfirmationModal(this), 500)
                    }}
                  />
                )}

                <ScoreOverview
                  scores={community.score.values}
                  className="mab-4"
                  onStepClick={step => handleCompleteProfileGuide(step)}
                />

                <Tabs
                  ref={tabsComponent => {
                    tabsRef = tabsComponent
                  }}
                  initialTabActive="#overview"
                  isDesktopOnly={true}
                  onTabChange={() => {
                    hasPendingChanges && showConfirmationModal(this)
                  }}
                  id="community-tabs"
                >
                  <Tab link="#overview">Overview</Tab>
                  <Tab link="#pricing">Pricing</Tab>
                  <Tab link="#care-staffing">Care & Staffing</Tab>
                  <Tab link="#accommodations">Accommodations</Tab>
                  <Tab link="#lifestyle">Lifestyle</Tab>
                  <FeatureToggle name="events">
                    <Tab link="#events">Events</Tab>
                  </FeatureToggle>
                </Tabs>
                <div
                  id="overview"
                  className={cx(
                    Tabs.tabContentClass,
                    Tabs.desktopOnly,
                    Tabs.activeTabContent
                  )}
                >
                  <Overview
                    community={community}
                    isFetching={isFetching}
                    forwardedRef={this.overviewform}
                    hasEditAccess={hasEditAccess}
                    setPendingChanges={(status, onSaveAndLeave, onDontSave) => {
                      if (hasPendingChanges !== status) {
                        setPendingChanges(this, status)
                      }
                      onSaveAndLeaveHandler = onSaveAndLeave
                      onDontSaveHandler = onDontSave
                    }}
                    setUpdatedCommunity={communityResponse =>
                      this.setState({
                        [STATE_COMMUNITY]: communityResponse
                      })
                    }
                    newLogo={newLogoAdded}
                    onLogoAdded={() => {
                      this.setState({ [STATE_NEW_LOGO]: null })
                    }}
                  />
                </div>
                <div
                  id="pricing"
                  className={cx(Tabs.tabContentClass, Tabs.desktopOnly)}
                >
                  <Pricing
                    isAllInclusive={community.isAllInclusive}
                    fees={community.fees}
                    roomCharges={community.roomCharges}
                    communityId={community.id}
                    isFetching={isFetching}
                    readOnly={!hasEditAccess}
                    setPendingChanges={(status, onSaveAndLeave, onDontSave) => {
                      if (hasPendingChanges !== status) {
                        setPendingChanges(this, status)
                      }
                      onSaveAndLeaveHandler = onSaveAndLeave
                      onDontSaveHandler = onDontSave
                    }}
                  />
                </div>
                <div
                  id="care-staffing"
                  className={cx(Tabs.tabContentClass, Tabs.desktopOnly)}
                >
                  <CareStaffing
                    communityId={community.id}
                    careStaffing={careStaffing}
                    readOnly={!hasEditAccess}
                    setPendingChanges={(status, onSaveAndLeave, onDontSave) => {
                      if (hasPendingChanges !== status) {
                        setPendingChanges(this, status)
                      }
                      onSaveAndLeaveHandler = onSaveAndLeave
                      onDontSaveHandler = onDontSave
                    }}
                    setUpdatedCommunity={communityResponse =>
                      this.setState({
                        [STATE_COMMUNITY]: communityResponse
                      })
                    }
                  />
                </div>
                <div
                  id="accommodations"
                  className={cx(Tabs.tabContentClass, Tabs.desktopOnly)}
                >
                  <Accommodations
                    communityId={community.id}
                    accommodations={accommodations}
                    readOnly={!hasEditAccess}
                    setPendingChanges={(status, onSaveAndLeave, onDontSave) => {
                      if (hasPendingChanges !== status) {
                        setPendingChanges(this, status)
                      }
                      onSaveAndLeaveHandler = onSaveAndLeave
                      onDontSaveHandler = onDontSave
                    }}
                    setUpdatedCommunity={communityResponse =>
                      this.setState({
                        [STATE_COMMUNITY]: communityResponse
                      })
                    }
                  />
                </div>
                <div
                  id="lifestyle"
                  className={cx(Tabs.tabContentClass, Tabs.desktopOnly)}
                >
                  <LifeStyle
                    communityId={community.id}
                    lifestyle={lifestyle}
                    readOnly={!hasEditAccess}
                    setPendingChanges={(status, onSaveAndLeave, onDontSave) => {
                      if (hasPendingChanges !== status) {
                        setPendingChanges(this, status)
                      }
                      onSaveAndLeaveHandler = onSaveAndLeave
                      onDontSaveHandler = onDontSave
                    }}
                    setUpdatedCommunity={communityResponse =>
                      this.setState({
                        [STATE_COMMUNITY]: communityResponse
                      })
                    }
                  />
                </div>
                <FeatureToggle name="events">
                  <div
                    id="events"
                    className={cx(Tabs.tabContentClass, Tabs.desktopOnly)}
                  >
                    <Events
                      communityId={community.id}
                      location={location}
                      readOnly={!hasEditAccess}
                      setPendingChanges={(
                        status,
                        onSaveAndLeave,
                        onDontSave
                      ) => {
                        if (hasPendingChanges !== status) {
                          setPendingChanges(this, status)
                        }
                        onSaveAndLeaveHandler = onSaveAndLeave
                        onDontSaveHandler = onDontSave
                      }}
                    />
                  </div>
                </FeatureToggle>
              </MediaObject.Content>
            </MediaObject>
          </BeforeUnload>
        )}
      </Layout>
    )
  }
}

Community.propTypes = {
  id: string,
  location: object,
  path: string,
  hasEditAccess: bool,
  setSyntheticBreadcrumbItems: func
}

Community.defaultProps = {
  id: null,
  location: null,
  path: '/',
  hasEditAccess: false,
  setSyntheticBreadcrumbItems: () => null
}

const CommunityWrapper = props => (
  <SupportEditMode>
    {hasEditAccess => <Community hasEditAccess={hasEditAccess} {...props} />}
  </SupportEditMode>
)

const mapStateToProps = createStructuredSelector({})

export default connect(mapStateToProps, actions)(CommunityWrapper)
