import { useEffect, useRef } from 'react'
import DOM from '@modules/dom'

const useBeforeUnload = (handler = () => {}) => {
  if (process.env.NODE_ENV !== 'production' && typeof handler !== 'function') {
    throw new TypeError(
      `Expected "handler" to be a function, not ${typeof handler}.`
    )
  }

  const handlerRef = useRef(handler)

  useEffect(() => {
    handlerRef.current = handler
  }, [handler])

  useEffect(() => {
    const handleBeforeUnload = event => {
      let returnValue

      if (typeof handlerRef.current === 'function') {
        returnValue = handlerRef.current(event)
      }

      if (event.defaultPrevented) {
        event.returnValue = ''
      }

      if (typeof returnValue === 'string') {
        event.returnValue = returnValue
        return returnValue
      }
    }

    DOM.addMultipleEventListeners(window, ['beforeunload'], handleBeforeUnload)

    return () => {
      DOM.removeMultipleEventListeners(window, ['beforeunload'], handleBeforeUnload)
    }
  }, [])
}

export default useBeforeUnload;
