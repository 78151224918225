import React from 'react'
import { Router } from '@reach/router'

// compositions
import IndexPage from '@compositions/Communities/components/Index'
import CommunityPage from '@compositions/Communities/components/Community'

const CommunitiesPage = () => (
  <Router>
    <IndexPage path="/communities" />
    <CommunityPage path="/communities/:id" />
  </Router>
)

export default CommunitiesPage
