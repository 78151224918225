import React, { Component } from 'react'
import { string, number, oneOfType, object, bool } from 'prop-types'
import cx from 'classnames'

// modules
import Url from '@modules/url'
import {
  fetchEvents,
  handleEventDelete,
  handleEventCreate,
  handleEventUpdate
} from './modules/helpers'
import {
  STATE_EVENTS_REQUEST_STATUS,
  STATE_EVENTS_REQUEST_ERROR,
  STATE_EVENTS_REQUEST_SUCCEED,
  STATE_REMOVING_EVENT_ID,
  STATE_IS_EVENT_ACTION_MODAL,
  STATE_IS_EVENT_FORM_ENABLED,
  STATE_EVENT_VALIDATION_ERRORS,
  STATE_EVENT_ACTION_STATUS,
  STATE_EVENT_MODEL,
  STATE_ACTION_VERB
} from './modules/constants'

// components
import Paginator from '@components/Paginator'
import List from './components/List'
import Icon from '@components/Icon'
import Form from '@components/Form'
import Button from '@components/Button'
import Modal from '@components/Modal'

import EventActionPoly from './components/EventActionPoly'

function handleModalExit(ctx) {
  ctx.setState({
    [STATE_EVENT_ACTION_STATUS]: false,
    [STATE_EVENT_MODEL]: {},
    [STATE_EVENT_VALIDATION_ERRORS]: {},
    [STATE_IS_EVENT_FORM_ENABLED]: true,
    [STATE_IS_EVENT_ACTION_MODAL]: false
  })
}

function createActionCallback(err, res, ctx) {
  ctx.setState({ [STATE_EVENT_ACTION_STATUS]: false })
  if (err)
    return ctx.setState({ [STATE_EVENT_VALIDATION_ERRORS]: err.errors.located })

  ctx.setState(state => {
    let payload = ctx.state[STATE_EVENTS_REQUEST_SUCCEED]
    const newResults = payload.results.slice()
    newResults.unshift(res.results)

    return {
      [STATE_EVENTS_REQUEST_SUCCEED]: { ...payload, results: newResults }
    }
  })

  handleModalExit(ctx)
}

function editActionCallback(err, res, model, ctx) {
  ctx.setState({ [STATE_EVENT_ACTION_STATUS]: false })
  if (err)
    return ctx.setState({ [STATE_EVENT_VALIDATION_ERRORS]: err.errors.located })

  ctx.setState(state => {
    let payload = ctx.state[STATE_EVENTS_REQUEST_SUCCEED]
    const newResults = payload.results.slice()
    newResults[model._index] = { ...newResults[model._index], ...model }

    return {
      [STATE_EVENTS_REQUEST_SUCCEED]: { ...payload, results: newResults }
    }
  })

  handleModalExit(ctx)
}

class Events extends Component {
  state = {
    [STATE_EVENTS_REQUEST_STATUS]: false,
    [STATE_EVENTS_REQUEST_ERROR]: null,
    [STATE_EVENTS_REQUEST_SUCCEED]: null,
    [STATE_IS_EVENT_ACTION_MODAL]: false,
    [STATE_REMOVING_EVENT_ID]: null,
    [STATE_IS_EVENT_FORM_ENABLED]: false,
    [STATE_EVENT_ACTION_STATUS]: false,
    [STATE_EVENT_VALIDATION_ERRORS]: {},
    [STATE_EVENT_MODEL]: {},
    [STATE_ACTION_VERB]: 'post'
  }

  constructor(props) {
    super(props)
    this.eventsForm = React.createRef()
  }

  componentDidMount() {
    const { communityId, location } = this.props
    if (communityId) {
      const { search } = location || { search: '' }
      const params = Url.parseSearch(search)
      fetchEvents(communityId, params, this)
    }
  }

  componentDidUpdate(prevProps) {
    const prevSearch = prevProps.location.search
    const search = this.props.location.search

    if (prevSearch !== search) {
      fetchEvents(this.props.communityId, Url.parseSearch(search), this)
    }
  }

  render() {
    const { location, communityId, readOnly } = this.props

    const {
      [STATE_EVENTS_REQUEST_STATUS]: eventsRequestStatus,
      [STATE_EVENTS_REQUEST_ERROR]: eventsRequestError,
      [STATE_EVENT_ACTION_STATUS]: eventActionStatus,
      [STATE_EVENTS_REQUEST_SUCCEED]: eventsResponse,
      [STATE_IS_EVENT_ACTION_MODAL]: isEventActionModal,
      [STATE_IS_EVENT_FORM_ENABLED]: isEventFormEnabled,
      [STATE_EVENT_VALIDATION_ERRORS]: eventValidationErrors,
      [STATE_EVENT_MODEL]: eventModel,
      [STATE_ACTION_VERB]: actionVerb
    } = this.state

    eventsRequestError && console.warn(eventsRequestError)
    const { results: events, meta } = eventsResponse || {
      results: [],
      meta: {}
    }
    const path = (location && location.pathname) || ''
    const searchAsObject = Url.parseSearch(location.search)

    return eventsRequestError ? (
      <div>An error occurred while loading data...</div>
    ) : (
      <div className="di-f fldi-c mat-3">
        <Form
          ref={this.eventsForm}
          onValidSubmit={model => {
            if (readOnly) return false
            const date = new Date(model.date)
            const startDate = new Date(model.startDate)
            const endDate = new Date(model.endDate)

            const updatedStartDate = new Date(date)
            updatedStartDate.setHours(startDate.getHours())
            updatedStartDate.setMinutes(startDate.getMinutes())

            const updatedEndDate = new Date(date)
            updatedEndDate.setHours(endDate.getHours())
            updatedEndDate.setMinutes(endDate.getMinutes())

            model.startDate = updatedStartDate.toLocaleString()
            model.endDate = updatedEndDate.toLocaleString()

            this.setState(state => ({
              [STATE_IS_EVENT_FORM_ENABLED]: false,
              [STATE_EVENT_VALIDATION_ERRORS]: {},
              [STATE_EVENT_ACTION_STATUS]: true
            }))

            const composedModel = { communityId, ...model }

            let method =
              actionVerb === 'post'
                ? (err, res) =>
                    handleEventCreate(composedModel, (err, res) =>
                      createActionCallback(err, res, this)
                    )
                : (err, res) =>
                    handleEventUpdate(
                      model.eventId,
                      composedModel,
                      (err, res) => editActionCallback(err, res, model, this)
                    )

            method()
          }}
          onInvalid={() =>
            this.setState(state => ({ [STATE_IS_EVENT_FORM_ENABLED]: false }))
          }
          onValid={() =>
            this.setState(state => ({ [STATE_IS_EVENT_FORM_ENABLED]: true }))
          }
        >
          <Modal
            header={<strong>Event</strong>}
            footer={() => {
              return (
                <>
                  <Button
                    palette="white"
                    className="pa-2"
                    onClick={() => handleModalExit(this)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="pa-2"
                    type="submit"
                    disabled={!isEventFormEnabled}
                  >
                    {eventActionStatus ? 'Saving...' : 'Done'}
                  </Button>
                </>
              )
            }}
            onToggleActive={() =>
              this.setState({
                [STATE_IS_EVENT_ACTION_MODAL]: !isEventActionModal
              })
            }
            isActive={isEventActionModal}
            onBackdropClick={() => null}
          >
            <EventActionPoly
              validationErrors={eventValidationErrors}
              model={eventModel}
            />
          </Modal>
        </Form>

        {!readOnly && (
          <section>
            <Button
              className={cx('mat-4', 'pay-2')}
              palette="white"
              type="button"
              onClick={() => {
                this.setState({
                  [STATE_IS_EVENT_ACTION_MODAL]: true,
                  [STATE_ACTION_VERB]: 'post',
                  [STATE_EVENT_MODEL]: {}
                })
              }}
            >
              <Icon className="mar-0" name="plus" size={12} />
              <span className="fosi-2 co-gray-4 mal-1">Add Event</span>
            </Button>
          </section>
        )}

        {eventsRequestStatus ? (
          <div>Loading...</div>
        ) : (
          <>
            <List
              events={events}
              onDelete={handleEventDelete(this)}
              onEdit={event => {
                if (readOnly) return false
                this.setState({
                  [STATE_IS_EVENT_ACTION_MODAL]: true,
                  [STATE_EVENT_MODEL]: event,
                  [STATE_ACTION_VERB]: 'put'
                })
              }}
            />
          </>
        )}
        {events && events.length > 0 && (
          <Paginator
            onClickNext={() => {
              Url.updateSearch(path, {
                ...searchAsObject,
                page: (+searchAsObject.page || 1) + 1
              })
            }}
            onClickPrev={() => {
              Url.updateSearch(path, {
                ...searchAsObject,
                page: (+searchAsObject.page || 1) - 1
              })
            }}
            onClickStep={step => {
              Url.updateSearch(path, {
                ...searchAsObject,
                page: step
              })
            }}
            currentPage={(meta && meta.page) || 1}
            totalPages={Math.ceil(
              (meta.totalItems && meta.totalItems / meta.itemsPerPage) || 1
            )}
            className="mat-3 juco-fe"
          />
        )}
      </div>
    )
  }
}

Events.propTypes = {
  communityId: oneOfType([string, number]).isRequired,
  readOnly: bool,
  location: object
}

Events.defaultProps = {
  readOnly: false,
  location: null
}

export default Events
