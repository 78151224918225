import React from 'react'
import { node, string, any } from 'prop-types'

const Section = props => (
  <section id={props.id} className="pat-4">
    {props.title && <h3 className="co-black fosi-4 mab-2">{props.title}</h3>}
    {props.description && <p className="fosi-2 pab-4">{props.description}</p>}
    {props.children}
  </section>
)

Section.propTypes = {
  title: node.isRequired,
  id: string,
  description: string,
  children: any
}

Section.defaultProps = {
  id: null,
  description: null,
  children: null
}

export default Section
