export const SORTBY_COMMUNITY_NAME = 0
export const SORTBY_CONTACT = 1
export const SORTBY_ADDRESS = 2
export const SORTBY_DIVISION = 3
export const SORTBY_DATE_UPDATED = 4
export const SORTBY_REGION = 5 
export const SORTBY_SCORE = -1 // TODO: Add order by score support, if necessary
export const SORTBY_INVOICE = -1 

export const COLUMNS = [
  { 
    sortColumn: SORTBY_COMMUNITY_NAME,
    text: 'Name',
    classNamePointer: 'name',
    isFilter: true
  },
  {
    sortColumn: SORTBY_CONTACT,
    text: 'Primary Contact',
    classNamePointer: 'contact',
    isFilter: true
  },
  {
    sortColumn: SORTBY_ADDRESS,
    text: 'Address',
    classNamePointer: 'address',
    isFilter: true
  },
  {
    sortColumn: SORTBY_DIVISION,
    text: 'Division',
    classNamePointer: 'division',
    isFilter: true
  },
  {
    sortColumn: SORTBY_REGION,
    text: 'Region',
    classNamePointer: 'region',
    isFilter: true
  },
  {
    sortColumn: SORTBY_DATE_UPDATED,
    text: 'Date Updated',
    classNamePointer: 'dateUpdated',
    isFilter: true
  },
  {
    sortColumn: SORTBY_SCORE,
    text: 'Score',
    classNamePointer: 'score'
  },
  {
    sortColumn: SORTBY_INVOICE,
    text: 'Invoices',
    classNamePointer: 'invoices'
  }
]
