import React, { useState, useEffect } from 'react'
import { any, string, array } from 'prop-types'

// modules
import UserRoles from '@modules/userRoles'
import Auth from '@modules/auth'
import {
  USER_ROLES,
  IMPERSONATED_USER_ROLES,
  EDIT_COMMUNITIES
} from '@modules/constants'

const SupportEditMode = ({ userType, rolesNeeded, children }) => {
  const [state, setState] = useState({
    error: null,
    hasEditingAccess: true,
    loading: true
  })
  const { hasEditingAccess, error, loading } = state
  useEffect(() => {
    setState(preState => ({ ...preState, loading: true }))
    Auth.isoIsImpersonating((_, isImpersonating) => {
      const userTypeCalc =
        userType || (isImpersonating ? IMPERSONATED_USER_ROLES : USER_ROLES)
      const rolesNeededCalc = rolesNeeded || [EDIT_COMMUNITIES]
      UserRoles.hasNeededRoles(
        userTypeCalc,
        rolesNeededCalc,
        (error, hasAccess) => {
          if (error) {
            return setState(prevState => ({
              ...prevState,
              loading: false,
              error
            }))
          }
          setState({
            loading: false,
            error: null,
            hasEditingAccess: hasAccess
          })
        }
      )
    })
  }, [rolesNeeded, userType])
  return loading ? (
    <p>Validating user permissions...</p>
  ) : (
    children(!error && hasEditingAccess)
  )
}

SupportEditMode.propTypes = {
  userType: string,
  rolesNeeded: array,
  children: any
}

SupportEditMode.defaultProps = {
  userType: null,
  rolesNeeded: null,
  children: null
}

export default SupportEditMode
