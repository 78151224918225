import React from 'react'
import { string, oneOfType, number, array, func, bool } from 'prop-types'
import DynamicFormFields from '../DynamicFormFields'

const Accommodations = ({
  communityId,
  accommodations,
  setPendingChanges,
  readOnly
}) => {
  return (
    <DynamicFormFields
      key="accomodations"
      communityId={communityId}
      fields={accommodations}
      setPendingChanges={setPendingChanges}
      readOnly={readOnly}
    />
  )
}

Accommodations.propTypes = {
  communityId: oneOfType([string, number]).isRequired,
  accommodations: array,
  readOnly: bool,
  setPendingChanges: func
}

Accommodations.defaultProps = {
  accommodations: [],
  readOnly: false,
  setPendingChanges: () => null
}
export default Accommodations
