import React, { Component, Fragment } from 'react'
import cx from 'classnames'
import { oneOfType, string, number, bool, node, array, func } from 'prop-types'

import { CheckboxSimple as Checkbox } from '@components/Checkbox'
import TextAreaGroup from '../TextAreaGroup'
import InputNumber from '../InputNumber'

import styles from './_.module.scss'

const checkboxPropTypes = {
  id: number.isRequired,
  tagId: string,
  type: string,
  value: oneOfType([string, number, bool]),
  name: string
}

const checkboxDefaultProps = {
  tagId: null,
  label: null,
  value: null,
  name: null
}

const STATE_IS_CHILD_DISABLED = 'isChildDisabled'
class CheckboxField extends Component {
  constructor(props) {
    super(props)
    const cleanValue = parseInt(props.value)
    this.state = {
      [STATE_IS_CHILD_DISABLED]: cleanValue === 0 || false
    }
  }
  render() {
    const {
      tagId,
      id,
      label,
      type,
      value,
      name,
      items,
      readOnly,
      onChange
    } = this.props
    const cleanValue = parseInt(value)
    const isChildDisabled = this.state[STATE_IS_CHILD_DISABLED] || readOnly
    return (
      <div className="di-f fldi-c pab-4 fosi-2">
        <Checkbox
          id={tagId}
          name={`${name}[${id}]`}
          title={label}
          value={cleanValue || 0}
          disabled={readOnly}
          onChange={(_, checked) => {
            if (readOnly) return false
            this.setState({ [STATE_IS_CHILD_DISABLED]: !checked })
            onChange({ name, id, value: checked })
          }}
        />
        <div
          className={cx('pal-4', 'max-2', 'co-gray-3', {
            [styles.disabled]: isChildDisabled,
            'cu-na': isChildDisabled
          })}
        >
          {items &&
            items.map((item, index) => (
              <Fragment key={index}>
                {item.type === 'input_number' && (
                  <InputNumber
                    parent={{ type }}
                    {...item}
                    onChange={onChange}
                    disabled={isChildDisabled}
                  />
                )}
                {item.type === 'checkbox' && (
                  <Checkbox
                    className={cx('pay-2', { 'co-gray-4': !isChildDisabled })}
                    id={item.tagId}
                    name={item.name}
                    title={item.label}
                    value={parseInt(item.value) || 0}
                    disabled={isChildDisabled || readOnly}
                    onChange={(_, checked) =>
                      !readOnly &&
                      onChange({ name: item.name, id: item.id, value: checked })
                    }
                  />
                )}
              </Fragment>
            ))}
        </div>
      </div>
    )
  }
}

CheckboxField.propTypes = {
  ...checkboxPropTypes
}

CheckboxField.defaultProps = {
  ...checkboxDefaultProps
}

const CheckboxTwoColumns = ({ children }) => (
  <div className="di-f fldi-r">{children}</div>
)

CheckboxTwoColumns.propTypes = {
  children: node
}

CheckboxTwoColumns.defaultProps = {
  children: null
}

const CheckboxGroup = ({ items, children, readOnly, onChange }) => {
  return (
    <>
      <div className="di-f fldi-r juco-sb flwr-w">{children}</div>
      <div className="di-f fldi-c juco-sb flwr-w">
        {items.length > 0 &&
          items.map(
            (item, index) =>
              item.type === 'textarea' && (
                <div className="di-f fldi-c juco-sb" key={index}>
                  <TextAreaGroup.Field
                    {...item}
                    readOnly={readOnly}
                    onChange={onChange}
                  />
                </div>
              )
          )}
      </div>
    </>
  )
}

CheckboxGroup.propTypes = {
  items: array,
  children: node,
  readOnly: bool,
  onChange: func
}

CheckboxGroup.defaultProps = {
  items: [],
  children: null,
  readOnly: false,
  onChange: () => null
}

CheckboxGroup.TwoColumns = CheckboxTwoColumns
CheckboxGroup.Field = CheckboxField

export default CheckboxGroup
