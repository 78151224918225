import React, { Component } from 'react'
import { array, string, number, oneOfType, func, bool } from 'prop-types'

// modules
import {
  handleValidSubmit,
  handleApiResponse
} from '@compositions/Communities/modules/helpers'

// components
import Form from '@components/Form'
import Button from '@components/Button'
import { THEMES } from '@components/constants'
import FeesTable from './components/FeesTable'
import RoomChargesTable from './components/RoomChargesTable'

import {
  STATE_FORM_IS_VALID,
  STATE_FORM_REQUEST_STATUS,
  STATE_FORM_REQUEST_ERROR,
  STATE_FORM_REQUEST_SUCCEED,
  STATE_COMMUNITY
} from '@compositions/Communities/modules/constants'

import {
  orderByChildren,
  feesToArray,
  roomChargesFill
} from './modules/helpers'

const handleSubmit = (params, setPendingChanges, setUpdatedCommunity, ctx) => {
  ctx.setState(
    { [STATE_FORM_REQUEST_STATUS]: true },
    handleValidSubmit(
      params,
      handleApiResponse(state => {
        setUpdatedCommunity(state[STATE_COMMUNITY])
        ctx.setState(state)
        setPendingChanges(false)
      })
    )
  )
}

class Pricing extends Component {
  state = {
    [STATE_FORM_IS_VALID]: false,

    [STATE_FORM_REQUEST_STATUS]: false,
    [STATE_FORM_REQUEST_ERROR]: null,
    [STATE_FORM_REQUEST_SUCCEED]: false
  }

  constructor(props) {
    super(props)
    this.pricingForm = React.createRef()
  }

  render() {
    const {
      isAllInclusive,
      fees,
      roomCharges,
      communityId,
      setPendingChanges,
      setUpdatedCommunity,
      readOnly
    } = this.props

    const {
      [STATE_FORM_IS_VALID]: isValidForm,
      [STATE_FORM_REQUEST_STATUS]: isUpdating,
      [STATE_FORM_REQUEST_ERROR]: errorMessage
    } = this.state

    errorMessage && console.warn(errorMessage)

    const feesMap = orderByChildren(fees)
    const feesCareTypes = feesToArray(
      feesMap,
      fees.map(item => [item.code, item.description])
    )
    const roomChargesTypes = roomChargesFill(roomCharges)
    return (
      <Form
        ref={this.pricingForm}
        id="pricing-section"
        className="di-f fldi-c mat-3"
        onValidSubmit={model =>
          !readOnly &&
          handleSubmit(
            { communityId, model },
            setPendingChanges,
            setUpdatedCommunity,
            this
          )
        }
        onValid={() => this.setState({ [STATE_FORM_IS_VALID]: true })}
        onInvalid={() => this.setState({ [STATE_FORM_IS_VALID]: false })}
        onChange={() =>
          setPendingChanges(
            true,
            () =>
              handleSubmit(
                { communityId, model: this.pricingForm.current.getModel() },
                setPendingChanges,
                setUpdatedCommunity,
                this
              ),
            this.pricingForm.current.reset
          )
        }
      >
        {roomChargesTypes.length > 0 && (
          <RoomChargesTable
            roomCharges={roomChargesTypes}
            readOnly={readOnly}
          />
        )}
        {feesCareTypes.length > 0 && (
          <FeesTable
            fees={feesCareTypes}
            isAllInclusive={isAllInclusive}
            readOnly={readOnly}
          />
        )}
        {!readOnly && (
          <div className="di-f">
            <Button
              className="mat-4 pax-3 pay-2"
              theme={THEMES.primary}
              palette={THEMES.primary}
              disabled={!isValidForm || isUpdating}
            >
              {isUpdating ? 'Saving...' : 'Save Changes'}
            </Button>
          </div>
        )}
      </Form>
    )
  }
}

Pricing.propTypes = {
  fees: array.isRequired,
  roomCharges: array.isRequired,
  communityId: oneOfType([string, number]).isRequired,
  isAllInclusive: bool,
  readOnly: bool,
  setPendingChanges: func,
  setUpdatedCommunity: func
}

Pricing.defaultProps = {
  isAllInclusive: false,
  readOnly: false,
  setPendingChanges: () => null,
  setUpdatedCommunity: () => null
}

export default Pricing
