import React from 'react'
import { arrayOf, shape, bool } from 'prop-types'

// components
import GroupFields, {
  Header,
  Box,
  Fields,
  Field
} from '@compositions/GroupFields'
import NumericTextField from '@components/NumericTextField'

const RoomChargesTable = ({ roomCharges, readOnly }) => (
  <div className="may-4">
    <GroupFields>
      <h2 className="di-f alit-c juco-sb mab-2">Room Charges</h2>
      {roomCharges.map((careType, key) => (
        <Box key={key}>
          <Header>
            <div>
              {careType.description}
            </div>
          </Header>
          <Fields>
            <>
              {careType.roomTypes &&
                careType.roomTypes.map((roomCharge, careTypeKey) => (
                  <Field
                    order={roomCharge.order}
                    key={careTypeKey}
                    label={roomCharge.name}
                    columns={5}
                  >
                    {roomCharge.isFake ? (
                      <NumericTextField name="NA" className="wi-100" disabled />
                    ) : (
                      <NumericTextField
                        className="wi-100"
                        name={`roomCharges[roomTypeId-${
                          roomCharge.id
                        }][careTypeCode-${careType.code}]`}
                        placeholder="None"
                        value={roomCharge.amount}
                        maxLength={9}
                        config={{
                          maxIntegersLength: 4,
                          maxValue: 99999.99
                        }}
                        readOnly={readOnly}
                      />
                    )}
                  </Field>
                ))}
              <Field
                order={(careType.roomTypes && careType.roomTypes.length) || 1}
                label="Units"
                columns={5}
              >
                <NumericTextField
                  icon={<i />}
                  className="wi-100"
                  name={`careTypes[careTypeCode-${careType.code}]`}
                  placeholder="None"
                  value={careType.units}
                  maxLength={3}
                  config={{
                    maxIntegersLength: 3,
                    maxDecimals: -1,
                    maxValue: 999,
                    alwaysInteger: true
                  }}
                  readOnly={readOnly}
                />
              </Field>
            </>
          </Fields>
        </Box>
      ))}
    </GroupFields>
  </div>
)

RoomChargesTable.propTypes = {
  roomCharges: arrayOf(shape({})),
  readOnly: bool
}

RoomChargesTable.defaultProps = {
  roomCharges: [],
  readOnly: false
}

export default RoomChargesTable
