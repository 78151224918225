import React, { Component } from 'react'
import cx from 'classnames'

export default function(WrappedComponent, config = {}) {
  return class extends Component {
    render() {
      const {
        mobileOrder: mobileOrderProp, // eslint-disable-line react/prop-types
        tabletOrder: tabletOrderProp, // eslint-disable-line react/prop-types
        desktopOrder: desktopOrderProp, // eslint-disable-line react/prop-types
        className // eslint-disable-line react/prop-types
      } = this.props

      const mobileOrder = (mobileOrderProp && `or-po-${mobileOrderProp}`) || ''
      const tabletOrder = (tabletOrderProp && `or-tpo-${tabletOrderProp}`) || ''
      const desktopOrder = (desktopOrderProp && `or-${desktopOrderProp}`) || ''

      return (
        <WrappedComponent
          {...this.props}
          className={cx(mobileOrder, tabletOrder, desktopOrder, className)}
        />
      )
    }
  }
}
