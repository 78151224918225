import React, { Component } from 'react'
import cx from 'classnames'
import { array, string, number, oneOfType, func, bool } from 'prop-types'

// components
import Form from '@components/Form'
import Button from '@components/Button'
import TextField from '@components/TextField'
import { THEMES } from '@components/constants'
import RadioList from './components/RadioList'
import YesNoInput from './components/YesNoInput'
import InputNumber from './components/InputNumber'
import CheckboxGroup from './components/CheckboxGroup'
import TextAreaGroup from './components/TextAreaGroup'
import GenericComponent from './components/GenericComponent'

// modules
import {
  STATE_FORM_IS_VALID,
  STATE_FORM_REQUEST_STATUS,
  STATE_FORM_REQUEST_ERROR,
  STATE_FORM_REQUEST_SUCCEED,
  STATE_COMMUNITY
} from '@compositions/Communities/modules/constants'
import {
  handleValidSubmit,
  handleApiResponse
} from '@compositions/Communities/modules/helpers'

const handleOnSubmit = (
  params,
  setPendingChanges,
  setUpdatedCommunity,
  ctx
) => {
  ctx.setState(
    { [STATE_FORM_REQUEST_STATUS]: true },
    handleValidSubmit(
      params,
      handleApiResponse(state => {
        const communityData = state[STATE_COMMUNITY]
        if (communityData) {
          ctx.elements = {}
          setUpdatedCommunity(state[STATE_COMMUNITY])
          setPendingChanges(false)
        }
        ctx.setState(state)
      })
    )
  )
}

const mapper = {
  yes_no_input: GenericComponent,
  yes_no: YesNoInput,
  input_number: InputNumber,
  radios_group: RadioList,
  checkbox_horizontal: CheckboxGroup,
  checkbox: CheckboxGroup.Field,
  checkbox_vertical: CheckboxGroup.TwoColumns,
  textarea_full_width: TextAreaGroup,
  textarea: TextAreaGroup.Field
}

const PolyRecComponent = props => {
  const {
    id,
    type,
    title,
    description,
    items,
    onChange,
    refreshToken,
    readOnly
  } = props
  const PolyComponent = mapper[type] || GenericComponent
  if (!PolyComponent) return null
  let split = null
  if (type === 'checkbox_vertical') split = Math.ceil(items.length / 2)
  return (
    <GenericComponent key={`${refreshToken}-${id}`}>
      {title && <h2 className="pab-2 pat-4 co-black">{title}</h2>}
      {description && <p className="pab-4 fosi-2">{description}</p>}
      <PolyComponent {...props} readOnly={readOnly} onChange={onChange}>
        {split ? (
          <>
            <div className={cx('di-f', 'fldi-c')} style={{ flex: '1 0 41%' }}>
              {items.slice(0, split).map((item, index) => (
                <PolyRecComponent
                  key={index}
                  parent={props}
                  {...item}
                  onChange={onChange}
                  readOnly={readOnly}
                />
              ))}
            </div>
            <div className={cx('di-f', 'fldi-c')} style={{ flex: '1 0 41%' }}>
              {items.slice(split).map((item, index) => (
                <PolyRecComponent
                  key={index}
                  parent={props}
                  {...item}
                  onChange={onChange}
                  readOnly={readOnly}
                />
              ))}
            </div>
          </>
        ) : (
          items &&
          items.map((item, index) => (
            <PolyRecComponent
              key={index}
              parent={props}
              {...item}
              onChange={onChange}
              readOnly={readOnly}
            />
          ))
        )}
      </PolyComponent>
    </GenericComponent>
  )
}

PolyRecComponent.propTypes = {
  type: string.isRequired,
  id: number,
  name: string,
  title: string,
  label: string,
  description: string,
  readOnly: bool,
  items: array,
  refreshToken: number,
  onChange: func
}
PolyRecComponent.defaultProps = {
  id: null,
  name: '',
  title: '',
  label: '',
  description: null,
  readOnly: false,
  items: [],
  refreshToken: null,
  onChange: () => null
}

const STATE_FORM_REFRESH = 'formRefresh'

class DynamicFormFields extends Component {
  state = {
    [STATE_FORM_IS_VALID]: false,

    [STATE_FORM_REQUEST_STATUS]: false,
    [STATE_FORM_REQUEST_ERROR]: null,
    [STATE_FORM_REQUEST_SUCCEED]: false,

    [STATE_FORM_REFRESH]: 0
  }

  constructor(props) {
    super(props)
    this.form = React.createRef()
    this.elements = {}
  }
  onChange({ id, value }) {
    const { communityId, setPendingChanges, setUpdatedCommunity } = this.props
    this.elements[id] = { attributeId: id, value }
    setPendingChanges(
      true,
      () => {
        handleOnSubmit(
          {
            communityId,
            model: { attributes: Object.values(this.elements) }
          },
          setPendingChanges,
          setUpdatedCommunity,
          this
        )
      },
      () => {
        this.setState({ [STATE_FORM_REFRESH]: Math.floor(Math.random() * 100) })
        this.elements = {}
      }
    )
  }
  render() {
    const {
      communityId,
      setPendingChanges,
      setUpdatedCommunity,
      fields,
      readOnly
    } = this.props
    const {
      [STATE_FORM_IS_VALID]: isValidForm,
      [STATE_FORM_REQUEST_STATUS]: isUpdating,
      [STATE_FORM_REQUEST_ERROR]: errorMessage,

      [STATE_FORM_REFRESH]: refreshToken
    } = this.state
    errorMessage && console.warn(errorMessage)
    return (
      <Form
        ref={this.form}
        className="di-f fldi-c mat-3"
        onValidSubmit={() =>
          !readOnly &&
          handleOnSubmit(
            {
              communityId,
              model: { attributes: Object.values(this.elements) }
            },
            setPendingChanges,
            setUpdatedCommunity,
            this
          )
        }
        onValid={() => this.setState({ [STATE_FORM_IS_VALID]: true })}
        onInvalid={() => this.setState({ [STATE_FORM_IS_VALID]: false })}
      >
        <TextField
          type="hidden"
          className="di-n"
          name={`enableForm`}
          defaultValue={1}
        />
        {fields && fields.length > 0 && (
          <PolyRecComponent
            refreshToken={refreshToken}
            items={fields}
            type="GenericComponent"
            onChange={attrs => !readOnly && this.onChange(attrs)}
            readOnly={readOnly}
          />
        )}
        {!readOnly && (
          <div className="di-f">
            <Button
              className="mat-4 pax-3 pay-2"
              theme={THEMES.primary}
              palette={THEMES.primary}
              disabled={!isValidForm || isUpdating}
            >
              {isUpdating ? 'Saving...' : 'Save Changes'}
            </Button>
          </div>
        )}
      </Form>
    )
  }
}

DynamicFormFields.propTypes = {
  communityId: oneOfType([string, number]).isRequired,
  fields: array,
  readOnly: bool,
  setPendingChanges: func,
  setUpdatedCommunity: func
}

DynamicFormFields.defaultProps = {
  fields: [],
  readOnly: false,
  setPendingChanges: () => null,
  setUpdatedCommunity: () => null
}

export default DynamicFormFields
