import PropTypes from 'prop-types'
import useBeforeUnload from '@hooks/useBeforeUnload'

const BeforeUnload = props => {
  const { children = null, onBeforeUnload } = props

  useBeforeUnload(onBeforeUnload)

  return children
}

BeforeUnload.propTypes = {
  onBeforeUnload: PropTypes.func.isRequired,
  children: PropTypes.any
}

export default BeforeUnload
