import React from 'react'
import cx from 'classnames'
import { object, oneOfType, string, number, node, func, bool } from 'prop-types'

// components
import TextArea from '@components/TextArea'

const TextAreField = ({ parent, id, name, label, onChange, readOnly, ...rest }) =>
  parent.type === 'checkbox_horizontal' ? null : (
    <>
      {label && <p className="pat-2 fosi-2">{label}</p>}
      <TextArea
        {...rest}
        name={`${name}[${id}]`}
        readOnly={readOnly}
        onChange={evt => !readOnly && onChange({ name, id, value: evt.currentTarget.value })}
      />
    </>
  )

TextAreField.propTypes = {
  id: oneOfType([string, number]).isRequired,
  parent: object,
  name: string,
  label: string,
  readOnly: bool,
  onChange: func
}

TextAreField.defaultProps = {
  parent: { type: 'textarea_full_width' },
  name: null,
  label: null,
  readOnly: false,
  onChange: () => null
}

const TextAreaGroup = ({ parent, label, children }) => (
  <div
    className={cx(
      'di-f',
      'fldi-c',
      'fosi-2',
      !parent || (parent.type !== 'yes_no' && parent.type !== 'checkbox')
        ? 'pab-4'
        : ''
    )}
  >
    {label && <p className="pat-2 fosi-2">{label}</p>}
    <div className="di-f fldi-c">{children}</div>
  </div>
)

TextAreaGroup.propTypes = {
  parent: object,
  label: string,
  children: node
}

TextAreaGroup.defaultProps = {
  parent: {},
  label: null,
  children: null
}

TextAreaGroup.Field = TextAreField

export default TextAreaGroup
