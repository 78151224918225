import React from 'react'
import cx from 'classnames'
import { string, bool, object, number, func } from 'prop-types'

import NumericTextField from '@components/NumericTextField'

const customValidations = {
  // Minimum Age Requirement
  1: {
    maxIntegersLength: 2,
    maxValue: 99
  },
  // Hours/Day on Staff
  6: {
    maxIntegersLength: 2,
    maxValue: 24
  }
}
const InputNumber = ({
  id,
  name,
  label,
  value,
  disabled,
  readOnly,
  parent,
  onChange
}) => {
  const config = Object.assign(
    {
      maxIntegersLength: 3,
      maxDecimals: -1,
      maxValue: 999,
      alwaysInteger: true
    },
    customValidations[id] || {}
  )
  return (
    <div
      className={cx(
        'di-f',
        'fldi-c',
        'fosi-2',
        !parent || (parent.type !== 'yes_no' && parent.type !== 'checkbox')
          ? 'pab-4'
          : ''
      )}
    >
      {label && <p className="pat-2 fosi-2">{label}</p>}
      <div className="di-f fldi-r">
        <NumericTextField
          name={`${name}[${id}]`}
          defaultValue={parseInt(value) || 0}
          placeholder="0"
          maxLength={config.maxIntegersLength}
          config={config}
          disabled={disabled || readOnly}
          icon={<div />}
          onChange={evt =>
            onChange({ name, id, value: evt.currentTarget.value })
          }
        />
      </div>
    </div>
  )
}

InputNumber.propTypes = {
  id: number.isRequired,
  name: string.isRequired,
  label: string,
  value: string,
  disabled: bool,
  readOnly: bool,
  parent: object,
  onChange: func
}

InputNumber.defaultProps = {
  label: '',
  value: '',
  disabled: false,
  readOnly: false,
  parent: null,
  onChange: () => null
}

export default InputNumber
