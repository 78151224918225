import React, { useState } from 'react'
import cx from 'classnames'
import { any, string, bool, array, number, func } from 'prop-types'

// components
import Chart from '@components/Chart'
import Icon from '@components/Icon'

const MediaObject = props => (
  <>
    <div className="di-f alit-c">
      {props.icon}
      <div className="pal-1">
        <h3 className="co-black">{props.title}</h3>
        <p className="pat-1 fosi-1 co-gray-3">{props.description}</p>
      </div>
    </div>

    {props.footer}
  </>
)

MediaObject.propTypes = {
  icon: any,
  title: any,
  description: any,
  footer: any
}

MediaObject.defaultProps = {
  icon: null,
  title: null,
  description: null,
  footer: null
}

const Step = ({ isDone, children, onClick }) => (
  <div className="di-f juco-fs pa-1 par-3 fosi-2">
    {isDone ? (
      <>
        <Icon name="check" className="par-2 co-primary-2" size=".7rem" />
        <span className="co-gray-3">{children}</span>
      </>
    ) : (
      <a href="/#" onClick={onClick}>
        {children}
      </a>
    )}
  </div>
)

Step.propTypes = {
  isDone: bool.isRequired,
  children: any,
  onClick: func
}

Step.defaultProps = {
  children: null,
  onClick: () => null
}

const stepsMapper = {
  Description: 'Write a full description',
  Photos: 'Add at least 5 photos',
  Pricing: 'Complete pricing information'
}

const Steps = ({ className, steps, onStepClick }) => (
  <ul className={cx('di-f', 'juco-fs', className)}>
    {steps.map((step, index) => (
      <li key={index}>
        <Step
          children={stepsMapper[step.name]}
          isDone={step.value >= Math.floor(100 / steps.length)}
          onClick={event => {
            event.preventDefault()
            onStepClick(step.name)
          }}
        />
      </li>
    ))}
  </ul>
)

Steps.propTypes = {
  steps: array.isRequired,
  className: string,
  onStepClick: func
}

Steps.defaultProps = {
  className: '',
  onStepClick: () => null
}

const Donut = ({ progress }) => (
  <Chart.Donut size={60} progress={progress} thickness={3} />
)
Donut.propTypes = {
  progress: number.isRequired
}

const CompletedScore = () => (
  <MediaObject
    icon={<Donut progress={100} />}
    title="You have a great community profile!"
    description="As we continue to improve Partner Central we’ll give you new ways to increase your score."
  />
)

const PendingScore = props => (
  <MediaObject
    icon={<Donut progress={props.score} />}
    title="Improve your community profile"
    description="Referrals are more likely to respond to comprehensive information."
    footer={<Steps steps={props.scores} onStepClick={props.onStepClick} />}
  />
)

PendingScore.propTypes = {
  score: number,
  scores: array,
  onStepClick: func
}

PendingScore.defaultProps = {
  score: 0,
  scores: [],
  onStepClick: () => null
}

const calcFlooredScore = scores =>
  Math.floor(
    scores.reduce((acc, obj) => {
      return acc + obj.value
    }, 0)
  )

const ScoreOverview = ({ className, scores, onStepClick }) => {
  const [score] = useState(calcFlooredScore(scores))
  return (
    <section
      className={cx(
        'boco-gray-2',
        'bowi-1',
        'bost-s',
        'bora-1',
        'pa-3',
        'di-f',
        'fldi-c',
        'flwr-wr',
        className
      )}
    >
      {score >= 99 ? (
        <CompletedScore />
      ) : (
        <PendingScore score={score} scores={scores} onStepClick={onStepClick} />
      )}
    </section>
  )
}

ScoreOverview.propTypes = {
  scores: array.isRequired,
  className: string,
  onStepClick: func
}

ScoreOverview.defaultProps = {
  className: '',
  onStepClick: () => null
}

export default ScoreOverview
