import React from 'react'
import cx from 'classnames'
import { string, node } from 'prop-types'

const Box = ({ children, className }) => (
  <div className={cx('di-f', 'fldi-c', 'wi-100', 'pay-2', className)}>
    {children}
  </div>
)

Box.propTypes = {
  children: node,
  className: string
}
Box.defaultProps = {
  children: null,
  className: ''
}

export default Box
