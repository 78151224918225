import React from 'react';

const WarningBody= () => (
    <div className="di-f fldi-c max-2">
        <h4 className="fowe-b mab-1">A user designated as Primary Contact may not be removed.</h4>
        <span className="fosi-1 fowe-b">
            Please choose another Primary Contact for your community and try again.
        </span>
    </div>
)


export default WarningBody;